import React, { useState, useEffect, } from 'react';
import { Space, Card, Form, Table, Descriptions } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import Apploader from "../../../components/loader/loader";

import moment from 'moment';
import { connect } from 'dva';
import styles from './style.less';




const AddEditUser = props => {
	const [form] = Form.useForm();
	const { dispatch } = props;

	const [detail, setDetail] = useState();
	const [reportUser, setReportUser] = useState({});



	const [loading, setLoading] = useState(true);


	const [btnDis, setBtnDis] = useState(false)




	useEffect(() => {
		// console.log("date6gt76gt6fr ", detail.userLogin)
		let unmounted = false;
		window.scroll(0, 0);
		console.log(props.match.params.id)
		if (props.match && props.match.params.id) {

			setLoading(true);
			DetailFun(props.match.params.id)


		} else {
			form.resetFields();

		}
		return () => { unmounted = true; }
	}, [dispatch])


	const DetailFun = async (id) => {
		setLoading(true);


		try {
			await props.dispatch({ type: 'reportUser/getDetail', payload: { _id: id, } });
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}

	}

	const onFinish = val => {
		console.log(props, val)
	}

	useEffect(() => {
		let unmounted = false;


		if (props.match && props.match.params) {
			let detail = props.reportUser.detail;

			let list = props.reportUser.list
			if (!unmounted && detail && detail.status) {
				let data = detail.data;


				const { state } = props.location;
				if (state) {
					setReportUser({ ...state })
				}


				const extractedData = data.map((item) => ({
					name: item.user_id.name,
					email: item.user_id.email,
					message: item.message,
					updated_at: item.updated_at,
				}));

				if (extractedData) {

					setDetail(extractedData);
					form.setFieldsValue(extractedData);
				}


			} else if (!unmounted && detail && !detail.status) {
				setBtnDis(false);
			}
		}

		return () => {
			unmounted = true;
		};
	}, [props.reportUser.detail]);

	// ShowSizeChange = (current, size) =>
	// 	this.setState({ limit: size }, () => this.ListFun());

	const cancelFun = () => {
		form.resetFields();

		props.history.push('/reportUser');
	}



	const columns = [
		{
			title: <strong>Reported Name</strong>,
			dataIndex: 'name',
			render: (record) => {

				return <span>{record}</span>;
			},
		},
		{
			title: <strong>Report Email</strong>,
			dataIndex: 'email',
			render: (record) => {

				return <span>{record}</span>;
			},
		},
		{
			title: <strong>message</strong>,
			dataIndex: 'message',
			render: (record) => {

				return <span>{record}</span>;
			},
		},
		{
			title: <strong> Created On</strong>,
			dataIndex: 'updated_at',
			render: (record) => moment(record).format("MM/DD/YYYY"),
		},
	];

	return (
		<div>
			<Apploader show={loading} />
			<Card title={
				<Space>
					<LeftOutlined onClick={() => props.history.push('/reportUser')}
						style={{ fontSize: 20, marginBottom: 6 }} />
					<span > Report User Details</span>
				</Space>
			}>

				<Descriptions size={'middle'} bordered column={1}>
					<Descriptions.Item label="Name">{reportUser.name}</Descriptions.Item>
					<Descriptions.Item label="Email">{reportUser.email}</Descriptions.Item>
					{/* <Descriptions.Item label="Is Email Verified">{detail.isEmailVerified ? 'true' : 'false'}</Descriptions.Item> */}
					{/* <Descriptions.Item label="Phone">{detail.mobile_number}</Descriptions.Item> */}
					{/* <Descriptions.Item label="Is Mobile Verified">{detail.isMobileVerified ? 'true' : 'false'}</Descriptions.Item> */}
					{/* <Descriptions.Item label="Role">{detail.roles}</Descriptions.Item> */}
					{/* <Descriptions.Item label="Profile Created On">{detail.date}</Descriptions.Item> */}
				</Descriptions>
				{/* <CropImage visible={picModel} closeFun={() => setPicModel(false)} returnImg={getNewImage} resetVal={imageUrl} limit={1} aspect={9 / 12} /> */}
			</Card>

			<div className="innerContainer">
				<Card
					style={{ marginTop: "0" }}
					bodyStyle={{ padding: "0  " }}
				>
					<Table
						columns={columns}

						dataSource={detail || []}
						pagination={false}


					/>
				</Card>
			</div>
		</div>
	)
};

export default connect(({ reportUser, global, loading }) => ({
	reportUser: reportUser,
	global: global
}))(AddEditUser);