import React, { useState, useEffect, useRef, Fragment, useCallback } from 'react';
import { Row, Col, Empty, Modal, Card, Typography, Alert, Form, Input, Checkbox, Button, Space, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker, Avatar, message, InputNumber, Popconfirm } from 'antd';
import { LeftOutlined, LoadingOutlined, EditOutlined, CloseOutlined, PlusOutlined, MinusCircleOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import Apploader from '../../../components/loader/loader'
import UploadImages from '../../../components/sharing/upload-images'
import CropImage from '../../../components/sharing/crop-image'
import TextEditor from '../../../components/sharing/text-editor'
import moment from 'moment';
import { connect } from 'dva';
import styles from './style.less';
import { getSubCatbyCategory } from '../../../services/api';
// import { RMIUploader } from "react-multiple-image-uploader";
import MultiImageInput from 'react-multiple-image-input';
import HTMLDecoderEncoder from 'html-encoder-decoder';
import stores from '../../../models/stores';
import ReactAudioPlayer from 'react-audio-player';
import { useJsApiLoader, GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import GooglePlacesAutocomplete from 'react-places-autocomplete';
import {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
} from 'react-places-autocomplete';
import {
	antdIsValidString,

} from "../../../utils/functions";
import { MAP_API_KEY } from "../../../utils/constants";
// import GoogleMapReact from 'google-map-react';
//import { GoogleMap, StandaloneSearchBox, Marker } from "@react-google-maps/api";


const { Text } = Typography;
const { TextArea } = Input;
const timestemp = (new Date()).getTime();
const { RangePicker } = DatePicker;
const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 24, }, } };
const baseUrl = process.env.REACT_APP_ApiUrl;

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const AddEditStore = props => {
	const [form] = Form.useForm();
	const { dispatch } = props;
	const [isLoading, setIsLoading] = useState(true)
	const [carId, setCarId] = useState('');
	const [latLng, setLatLng] = useState({});

	const [autocomplete, setAutocomplete] = useState();

	const [storeImage, setStoreImage] = useState([]);

	const [showImage, setShowImage] = useState([])
	const [errors, setErrors] = useState({});


	const [localStorageInUserID, setLocalStorageInUserID] = useState([]);

	// const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
	const [currentLocation, setCurrentLocation] = useState({});




	const { isLoaded } = useJsApiLoader({ googleMapsApiKey: MAP_API_KEY });


	let CategoryList = []
	let CategoryId = []


	if (props?.category?.list?.data?.length > 0) {

		CategoryList = [...props.category.list.data];
		CategoryId = CategoryList.map(user => user._id);


	}

	useEffect(() => {

		let unmounted = false;
		window.scroll(0, 0);
		//props.dispatch({ type: 'blogsCategory/blogsCategoryList' });
		// props.dispatch({ type: 'category/categoryList' });

		if (props.match.params.id) {
			DetailFun(props.match.params.id)
			setCarId(true)
		} else {
			// form.resetFields();
			setCarId(false)
		}
		return () => { unmounted = true; }
	}, [])

	useEffect(() => {
		const storedData = localStorage.getItem('userId');
		setLocalStorageInUserID(storedData)
	}, [])




	useEffect(() => {

		let unmounted = false;

		if (props.stores.add) {
			setIsLoading(false);
			dispatch({ type: 'stores/clearAction' });

			if (props.stores.add.message) {
				props.history.push('/stores');
			}
		}

		if (props.stores.edit) {
			setIsLoading(false);
			dispatch({ type: 'stores/clearAction' });
			if (props.stores.edit.message) {
				props.history.push('/stores');

			}
		}
		// if(props.stores && props.stores.detail ){
		if (props.stores && props.stores.detail && props.match.params.id) {

			if (!props.stores?.detail?.data?.length > 0) {
				return
			}

			let data = props.stores?.detail?.data[0];


			const coordinates = {
				lat: data.lat,
				lng: data.lng
			}
			setLatLng(coordinates);

			const foundCategory = data.store_Category_ids.map((category) => category.id);

			const foundStoreImageUrls = data.storeImages.map((storeImage) => storeImage);


			let image = foundStoreImageUrls
			setShowImage(image)
			console.log('storeImages', image);
			setIsLoading(true)
			form.setFieldsValue({
				['store_name']: data.store_title,
				['number']: data.number,
				['location']: data.location_name,
				['select_category']: foundCategory,
				['_id']: data._id,
				['description']: data.description,
				['isActive']: data.isActive,


				// ['isPaid']: data.isPaid ,

			})
			setIsLoading(false)
		} else {
			setIsLoading(true)
			form.resetFields();
			const coordinates = {
				lat: '',
				lng: ''
			}
			setLatLng(coordinates);
			setIsLoading(false)
		}


		return () => { unmounted = true; }
	}, [props.stores])

	useEffect(() => {
		// Get the user's current location using the Geolocation API
		navigator.geolocation.getCurrentPosition((position) => {
			const { latitude, longitude } = position.coords;
			setCurrentLocation({ lat: latitude, lng: longitude });
		});
	}, []);

	const DetailFun = (id) => {
		props.dispatch({ type: 'stores/detailStore', payload: id });
	}

	const cancelFun = () => {
		form.resetFields();
		props.history.push('/stores');
	}


	useEffect(() => {

		let unmounted = false;
		window.scroll(0, 0);


		props.dispatch({ type: 'category/categoryList' });


		return () => {
			unmounted = true;
		};
	}, [dispatch]);
	const onStoreImageChange = event => {

		const files = event.target.files;

		setStoreImage(files);
		// Check if any files are selected
		if (files.length === 0) {
			return;
		}

		const fileArray = Array.from(files);

		// Check file types
		const invalidFiles = fileArray.filter(file => !['image/jpg', 'image/jpeg', 'image/png'].includes(file.type));

		if (invalidFiles.length > 0) {
			setErrors({ ...errors, imageTypeError: 'Please select image file type' });
		} else {
			setErrors({ ...errors, imageTypeError: '' });

		}


	};



	const onFinish = val => {




		const formData = new FormData();
		// if (storeImage) {
		for (let i = 0; i < storeImage.length; i++) {
			formData.append('storeImages', storeImage[i]);
		}

		// }


		// val.select_category.forEach((user, index) => {

		// 	formData.append(`store_Category_ids[${index}]`, user);
		// });
		// formData.append('type', 'ADMIN');
		formData.append('store_title', val.store_name);
		formData.append('number', val.number);

		formData.append('location_name', val.location);
		formData.append('description', val.description);
		formData.append('lat', latLng.lat);
		formData.append('lng', latLng.lng);
		formData.append('id', (props.stores?.detail?.data?.length > 0) ? props.stores?.detail?.data[0]._id : '');
		formData.append('isActive', val.isActive);
		formData.append('isPaid', val.isPaid);

		console.log('formData =-=-=-=-=-', formData)


		setIsLoading(true)
		try {
			if (!(errors.storeDurationError || errors.storeTypeError || errors.imageTypeError))

				if (props.match.params.id) {

					dispatch({ type: 'stores/EditStore', payload: formData });

				} else {

					formData.append('user_id', localStorageInUserID);
					formData.append('type', 'ADMIN');


					dispatch({ type: 'stores/AddStore', payload: formData });


				}
		} catch (error) {
			// Handle errors here
			console.error('Error during form submission:', error);
		} finally {
			setIsLoading(false);
		}

	}



	const onMarkerDragEnd = (event) => {

		const lat = event.latLng.lat();
		const lng = event.latLng.lng();

		const latitudeLongitude = {

			lat: lat,
			lng: lng
		}
		setLatLng(latitudeLongitude);

	};
	const onLoad = useCallback((props) => {
		// console.log('onLoad ---- ')

		setAutocomplete(props)
	}, [])


	const onPlaceChanged = () => {
		console.log('autocomplete ---- :', autocomplete)

		if (autocomplete !== null) {
			console.log('check -1--- ')
			// if (autocomplete?.getPlace()?.geometry?.location?.lat() == undefined || autocomplete?.getPlace()?.geometry?.location?.lng() == undefined) {
			// 	return
			// }

			const latitudeLongitude = {
				lat: autocomplete?.getPlace()?.geometry?.location?.lat(),
				lng: autocomplete?.getPlace()?.geometry?.location?.lng()

			}
			console.log('latitudeLongitude ---- :', latitudeLongitude)
			setLatLng(latitudeLongitude);
			form.setFieldsValue({
				['location']: autocomplete?.getPlace()?.formatted_address
			})
		}
	}




	const handleCancelImage = (image) => {


		const slug = props.match.params.id
		dispatch({
			type: 'stores/deleteStoreImage', payload: {
				slug: slug,
				image: image,
			},
		});
		DetailFun(props.match.params.id)






	};


	console.log('update', isLoading)

	return (
		<div>
			<Apploader show={isLoading} />
			<Card title={<span><LeftOutlined onClick={() => props.history.push('/stores')} />


				{carId ? 'Edit Store' : 'Add Store'}</span>} style={{ marginTop: "0" }}>

				{

					(errors.imageTypeError || errors.storeTypeError || errors.storeDurationError) ?

						<Alert
							message="Error "
							description="You are select wrong file type or store should be below than 10 min."
							type="error"
							closable

						/> : ''
				}

				<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">


					<Row gutter={15}>
						<Col sm={24} md={12}>

							<Form.Item name="store_name" label="Store Name" rules={antdIsValidString("Store Name")}  >

								<Input placeholder="Store Name" />
							</Form.Item>
						</Col>
					</Row>

					{/* <Row gutter={15}  >
						<Col sm={24} md={12}>
							<Form.Item name="select_category" label="Store Category" rules={[{ required: true, message: "This field is required!" }]} >
								<Select
									className="Select-field" mode="multiple" allowClear placeholder="Select Category"
									getPopupContainer={(triggerNode) => triggerNode}

									overlayStyle={{
										width: '300px', // Adjust the width as needed
										height: 'auto', // Set height to auto
									}}
									showSearch  // Enable search functionality
									optionFilterProp="children"  // Specify which property of the option will be used for filtering

									// Customize the filtering logic
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									maxTagCount={3} // Set the maximum number of displayed tags
									maxTagPlaceholder={(values) => `+ ${values.length} more`}
								>
									{CategoryList &&
										CategoryList.map((item, index) => (
											item.isActive && (
												<Select.Option key={index} value={item._id}>
													{item.name}
												</Select.Option>
											)
										))}
								 
								</Select>

							</Form.Item>
						</Col>
					</Row> */}



					<Row gutter={15} >
						<Col sm={24} md={12}>
							<Form.Item name="number" label="Contact Number" rules={[{ required: true, message: 'Field required.' },]}  >
								<Input type='number' placeholder="Contact Number" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={18}>
						<Col sm={14} md={14}>

							<LoadScript
								googleMapsApiKey={MAP_API_KEY}
								libraries={['places']}
							>
								{console.log("Map -1111------- ")}
								<Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} bounds={true} onUnmount={true}>
									<Row gutter={15}>
										<Col sm={24} md={12}>
											<Form.Item name="location" label="Location Address" rules={[{ required: true, message: 'Field required.' },]}  >
												<Input placeholder="Location" type="text" />
											</Form.Item>
										</Col>
									</Row>
								</Autocomplete>
							</LoadScript>

							{/* <LoadScript googleMapsApiKey={MAP_API_KEY} libraries={['places']} style={{ width: '100%', height: '300px', position: 'absolute' }}>

							<Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} bounds={true} onUnmount={true}>
								<Form.Item name="location" label="Location Name">
									<Input placeholder="Location" type="text" />
								</Form.Item>
							</Autocomplete>

						</LoadScript> */}

						</Col>
					</Row>



					<Row gutter={15}>
						<Col sm={24} md={12}>
							<Form.Item name="description" label="Description" rules={antdIsValidString("Description")}  >
								<textarea rows={4} style={{ width: '100%', padding: 10 }} placeholder={'Description'} ></textarea>
							</Form.Item>
						</Col>
					</Row>



					<Row gutter={15}>
						{
							props.stores?.detail?.data?.length > 0 && carId ?
								<Col sm={24} md={12}>
									<Form.Item name="store_image" label="Store Image" >
										<Input placeholder="Store Name" multiple type="file" onChange={(e) => onStoreImageChange(e)} />
									</Form.Item>
								</Col> : <Col sm={24} md={12}>
									<Form.Item name="store_image" label="Store Image" rules={[{ required: true, message: 'Field required.' },]}  >
										<Input placeholder="Store Name" multiple type="file" onChange={(e) => onStoreImageChange(e)} />
									</Form.Item>
								</Col>
						}

						{showImage.map((image, index) => (
							<div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: 10 }}>
								<img style={{ width: 100, height: 100 }} src={image} alt={`Image ${index}`} />

								<CloseOutlined style={{
									position: 'absolute',
									top: 0,
									right: 0,
									background: 'transparent',
									border: 'none',
									cursor: 'pointer',
									color: "#FFFF"
								}}
									onClick={() => handleCancelImage(image)} />

							</div>
						))}

					</Row>








					<div style={{ height: '300px', width: '700px', }}>
						{console.log("Map -2222------- ")}
						<LoadScript
							googleMapsApiKey={MAP_API_KEY}
							libraries={['places']}
						>

							<GoogleMap center={latLng.lat ? latLng : currentLocation} zoom={15} mapContainerStyle={{ width: '50%', height: '100%' }}>

								<Marker
									// onLoad={onLoad}
									// position={latLng}
									position={latLng.lat ? latLng : currentLocation}
									draggable={true}
									onDragEnd={(e) => onMarkerDragEnd(e)}
								/>
							</GoogleMap>
						</LoadScript>
					</div>

					<Form.Item name="isActive" valuePropName="checked" style={{ marginTop: '10px' }}>
						<Checkbox>isActive</Checkbox>
					</Form.Item>

					{/* <Form.Item  name="isPaid" valuePropName="checked" >
                  <Checkbox>Is Paid</Checkbox>
             	 </Form.Item> */}

					<Form.Item className="mb-0">
						<Button onClick={cancelFun}>Cancel</Button>&nbsp;&nbsp;
						<Button type="primary" className="btn-w25 btn-primary-light" onClick={() => form.submit()}>Save</Button>
					</Form.Item>


				</Form>



			</Card>
		</div>
	)
};

export default connect(({ category, stores, global, loading }) => ({
	stores: stores,
	global: global,
	category: category
}))(AddEditStore);