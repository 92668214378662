import React from "react";
import Apploader from "../../components/loader/loader";
import { connect } from "dva";
import {
	Card,
	Typography,
	Input,
	Button,
	Table,
	Row,
	Col,


} from "antd";
import {

	EyeOutlined,

} from "@ant-design/icons";

import moment from "moment";
// import AddEdit from './action/addEdit'
import axios from "axios";
const { Search } = Input;
const { Text } = Typography;

const baseUrl = process.env.REACT_APP_ApiUrl;

class ReportUserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 1000000000,
			// current: 1,
			current: 0,
			sortBy: "asc",
			addModel: false,
			inactive: false,
			searchText: "",
			loader: false,
			detail: "",
			count: 0,
			Addcount: 0,
			listData: [],
		};

		setTimeout(() => (document.title = "Report List"), 100);
		this.isUpdate = false;
	}

	componentDidMount() {
		this.ListFun();
	}

	ListFun = () => {
		const data = {
			limit: this.state.limit,
			page: this.state.current,
		};
		this.props.dispatch({ type: "reportUser/getList", payload: data });
	};

	ShowSizeChange = (current, size) =>
		this.setState({ limit: size }, () => this.ListFun());
	switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
	ChangeOrder = (val) =>
		this.setState(
			{ sortBy: this.state.sortBy === "asc" ? "desc" : "asc" },
			() => this.ListFun()
		);

	searchVal = (val) => {
		this.state.searchText = val;
		// const resultAutos = this.props.audios.list.filter((auto) =>
		const resultAutos = this.props.reportUser.list.data.filter((auto) =>
			auto.reportUserDetails.name.toLowerCase().includes(val.toLowerCase())
		);
		this.setState({ listData: resultAutos });
	};



	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot) {
		}
	}


	handleDeactiveUser = async (data) => {
		const { listData } = this.state;

		// Clone the listData array to avoid mutating the state directly
		let list_update = listData.map((item) => {
			if (item._id === data._id) {
				// Check if reportUserDetails exists before updating user_status
				if (item.reportUserDetails) {
					item.reportUserDetails.user_status = !item.reportUserDetails.user_status;
				}
			}
			return item;
		});

		// Update the state with the modified list_update
		this.setState({ listData: list_update });

		try {
			// Send the update to the server
			await axios.post(`${baseUrl}/api/user/status`, { id: data._id });
		} catch (error) {
			console.error("Error updating user status:", error);
		}
	};
	render() {

		const { inactive, limit, searchText, addModel, detail } = this.state;

		const { reportUser } = this.props;
		if (this.state.searchText == "") {

			this.state.listData = reportUser.list ? reportUser.list.data : [];
		}

		const columns = [
			{
				title: <strong>Report User Name</strong>,
				render: (record) => record.reportUserDetails.name, // Dynamic dataIndex function
			},
			{
				title: <strong>Report User Email</strong>,
				render: (record) => record.reportUserDetails.email, // Dynamic dataIndex function
			},
			{
				title: <strong>Total Report</strong>,
				render: (record) => record.count, // Dynamic dataIndex function
			},

			{
				title: <strong>Last Report On</strong>,
				render: (record) => moment(record.reports[0].updated_at).format("MM/DD/YYYY"),
			},
			{
				title: (
					<div>
						<strong> Status </strong>
						<span style={{ marginLeft: 35 }}></span>
					</div>
				),
				dataIndex: 'user_status',
				render: (val, data) => (
					<div>
						<Button
							type={data?.reportUserDetails?.user_status === true ? "primary" : "danger"}
							onClick={(e) => {
								this.handleDeactiveUser(data);

								console.log("primary danger", data);
							}}
						>
							{data.reportUserDetails?.user_status} {data?.reportUserDetails?.user_status ? "Activate" : "Deactivate"}
						</Button>
					</div>
				),
			},

			{
				title: <strong>Action</strong>,
				align: 'center',
				width: 150,
				render: (val, data) => {
					console.log('isUser', data); // Add this line to log the 'data' object

					return (
						<div>
							<Button
								type="primary"
								onClick={() => {
									// Pass the user's name and email as state

									this.props.history.push({
										pathname: `/reportUser/View/${data._id}`,
										state: {
											name: data.reportUserDetails.name,
											email: data.reportUserDetails.email,
											// date: data.reports.updated_at
										}
									});

								}}
							>
								<EyeOutlined />
							</Button>
							&nbsp;&nbsp;
							{/* Add the rest of your code */}
						</div>
					);
				},
			},
		];



		return (
			<div>
				<Apploader show={this.props.loading.global} />
				<Row
					className="TopActionBar"
					gutter={[16, 0]}
					justify="space-between"
					align="middle"
				>
					<Col>
						<Search
							placeholder="Search..."
							onChange={(e) => this.searchVal(e.target.value)}
							value={searchText}
						/>
					</Col>
					{/* <Col>
							<Button type="primary" onClick={() => this.props.history.push('/stores/add')}>Add</Button>
						</Col> */}
				</Row>

				<div className="innerContainer">
					<Card
						style={{ marginTop: "0" }}
						bodyStyle={{ padding: "0  " }}
					>
						<Table
							columns={columns}
							dataSource={this.state.listData}
							rowKey={(record) => record._id}
							onRow={(record, rowIndex) => {
								return {
									// onClick: event => this.setState({ addModel: true, detail: record })
								};
							}}
							pagination={{
								position: ["bottomLeft"],
								showTotal: (total, range) => (
									<Text style={{ marginLeft: "20px" }} type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
								),
								showSizeChanger: true,
								responsive: true,
								onShowSizeChange: (current, size) =>
									this.ShowSizeChange(current, size),
								pageSizeOptions: ["25", "50", "100", "250", "500"],
							}}
						/>
					</Card>
				</div>
			</div>
		);
	}
}

export default connect(({ reportUser, loading }) => ({
	reportUser,
	loading,
}))(ReportUserList);