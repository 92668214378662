import React from "react";
import Apploader from "../../components/loader/loader";
import { connect } from "dva";
import {
  Card,
  Typography,
  Input,
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Modal,
  message,
  Upload,
} from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  CommentOutlined,
  CarOutlined,
} from "@ant-design/icons";
import uploadFile from "../products/action/uploadFile";
import moment from "moment";
import AddEdit from "./action/addEdit";

// import AddEdit from './action/addEdit'
import axios from "axios";
const { Search } = Input;
const { Text } = Typography;

const baseUrl = process.env.REACT_APP_ApiUrl;

class ReportUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 1000000000,
      // current: 1,
      current: 0,
      sortBy: "asc",
      addModel: false,
      inactive: false,
      searchText: "",
      loader: false,
      detail: "",
      count: 0,
      Addcount: 0,
      listData: [],
    };

    setTimeout(() => (document.title = "BroadCast List"), 100);
    this.isUpdate = false;
  }

  componentDidMount() {
    this.ListFun();
  }

  ListFun = () => {
    const data = {
      limit: this.state.limit,
      page: this.state.current,
    };
    this.props.dispatch({
      type: "Subscription/subscriptionList",
      payload: data,
    });
  };

  ShowSizeChange = (current, size) =>
    this.setState({ limit: size }, () => this.ListFun());
  switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
  ChangeOrder = (val) =>
    this.setState(
      { sortBy: this.state.sortBy === "asc" ? "desc" : "asc" },
      () => this.ListFun()
    );

  searchVal = (val) => {
    this.state.searchText = val;
    // const resultAutos = this.props.audios.list.filter((auto) =>
    const resultAutos = this.props.Subscription.list.data.filter((auto) =>
      auto.title.toLowerCase().includes(val.toLowerCase())
    );
    this.setState({ listData: resultAutos });
  };

  // createCat = (val) => {
  // 	if (val) { this.ListFun() }
  // 	this.setState({ detail: '', addModel: false })
  // }

  // deleteStore = id => {

  // 	this.props.dispatch({ type: 'stores/deleteStore', payload: id });
  // }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  // 	if (this.props.stores.delete) {
  // 		this.props.dispatch({ type: 'stores/clearAction' });
  // 		this.ListFun();
  // 		return true
  // 	}
  // 	return null;
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
    }
  }

  render() {
    console.log("this.props -22----- :", this.props);
    const { inactive, limit, searchText, addModel, detail } = this.state;
    // const { audios } = this.props;
    const { Subscription } = this.props;
    if (this.state.searchText == "") {
      // this.state.listData = audios.list ? audios.list : [];
      this.state.listData = Subscription.list ? Subscription.list.data : [];

      //   console.log("check list data", this.props.su);
    }

    const columns = [
      {
        title: <strong>ID</strong>,
        // dataIndex: "ID",
        render: (record) => record._id, // Dynamic dataIndex function
      },
      {
        title: <strong>Plan ID</strong>,
        render: (record) => record.plan_id,
      },
      {
        title: <strong>Title</strong>,

        render: (record) => record.title,
      },
      {
        title: <strong>Price</strong>,

        render: (record) => record.price,
      },
      {
        title: <strong>Duration</strong>,
        dataIndex: "planDurations",
        // render: (record) => record.planDescription,
      },
      {
        title: <strong>Plan Description</strong>,
        dataIndex: "planDescription",
        // render: (record) => record.planDescription,
      },
      {
        title: <strong>Create On</strong>,
        render: (record) => moment(record.updated_at).format("MM/DD/YYYY"),
      },
      // {
      // 	title: <strong>Location Name</strong>,
      // 	dataIndex: 'location_name'
      // },
      // {
      // 	title: <strong>isActive</strong>, dataIndex: 'isActive',
      // 	render: (value, row) => {
      // 		return <span>{value === true ? "True" : "False"}</span>
      // 	}
      // },
      // {
      // 	title: <strong>Action</strong>, align: 'center',
      // 	render: (val, data) => <div onClick={e => e.stopPropagation()}>
      // 		<Button type="primary" onClick={() => { this.props.history.push('/stores/edit/' + data._id) }}><EditOutlined /></Button>&nbsp;&nbsp;
      // 		<Popconfirm title="Are you sure delete this store?" onConfirm={e => { this.deleteStore(data._id); e.stopPropagation() }} okText="Yes" cancelText="No" getPopupContainer={(triggerNode) => triggerNode}
      // 			overlayStyle={{
      // 				width: '300px',   // Adjust the width as needed
      // 				height: '100px',  // Adjust the height as needed

      // 			}} >
      // 			<Button type="danger" ><DeleteOutlined /></Button>
      // 		</Popconfirm>
      // 	</div>
      // },
    ];

    console.log("=========", this.state.listData);

    return (
      <div>
        <Apploader show={this.props.loading.global} />
        <Row
          className="TopActionBar"
          gutter={[16, 0]}
          justify="space-between"
          align="middle"
        >
          <Col>
            <Search
              placeholder="Search..."
              onChange={(e) => this.searchVal(e.target.value)}
              value={searchText}
            />
          </Col>
          {/* <Col>
            <Button
              type="primary"
              onClick={() => this.setState({ addModel: true })}
            >
              Add
            </Button>
          </Col> */}
        </Row>

        <div className="innerContainer">
          <Card
            style={{ marginTop: "0" }}
            bodyStyle={{ padding: "0  " }}
          >
            <Table
              columns={columns}
              dataSource={this.state.listData}
              rowKey={(record) => record._id}
              onRow={(record, rowIndex) => {
                return {
                  // onClick: event => this.setState({ addModel: true, detail: record })
                };
              }}
              pagination={{
                position: ["bottomLeft"],
                showTotal: (total, range) => (
                  <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
                ),
                showSizeChanger: true,
                responsive: true,
                onShowSizeChange: (current, size) =>
                  this.ShowSizeChange(current, size),
                pageSizeOptions: ["25", "50", "100", "250", "500"],
              }}
            />
          </Card>
        </div>

        <AddEdit
          visible={addModel}
          returnData={this.createCat}
          closeModel={() => this.setState({ addModel: false, detail: "" })}
          detail={this.state.detail}
        />
      </div>
    );
  }
}

export default connect(({ Subscription, loading }) => ({
  Subscription,
  loading,
}))(ReportUserList);
