import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Empty, Modal, Card, Row, Col, Typography, Alert, Form, Input, Checkbox, Button, Switch, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker } from 'antd';
import moment from 'moment';
import { connect } from 'dva';
import styles from './style.less';
import Apploader from '../../../components/loader/loader'

import {
	antdIsValidString,

} from "../../../utils/functions";
const { Text } = Typography;
const { TextArea } = Input;
const timestemp = (new Date()).getTime();
const { RangePicker } = DatePicker;
const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 24, }, } };

const limits = 10000000000

const baseUrl = process.env.REACT_APP_ApiUrl

const AddEdit = props => {
	const [form] = Form.useForm();
	const { dispatch } = props;
	const [stateId, setStateId] = useState('send_id')
	const [loading, setLoading] = useState(true);







	let UserList = []
	let UserListAllId = []

	if (props?.users?.list?.data?.length > 0) {

		UserList = [...props.users.list.data];
		UserListAllId = UserList.map(user => user._id);


	}






	useEffect(() => {

		let unmounted = false;
		window.scroll(0, 0);
		let data = { limit: limits, role: "USER" }
		setLoading(true)
		props.dispatch({ type: 'users/getList', payload: data });

		setLoading(false)
		return () => {
			unmounted = true;
		};
	}, [dispatch]);





	const onFinish = async val => {



		let data = {}
		if (val.select_user) {
			data = {

				title: val.title,
				message: val.message,
				type: val.send_id,
				userArray: val.select_user,
				roles: 'ADMIN',

			};
		} else {
			data = {

				title: val.title,
				message: val.message,
				type: val.send_id,
				userArray: UserListAllId,
				roles: 'ADMIN',

			};
		}



		console.log('values', data)


		setLoading(true);


		await dispatch({ type: 'broadCastNotification/broadCastAddEdit', payload: data, });

		if (data) {
			form.resetFields();

			setStateId('')

		}



		setLoading(false)



	}





	const handleChange = (val, data) => {

		setStateId(val)

	}


	return (

		<div>

			<Apploader show={loading} />
			<Row gutter={20} justify="center" align="middle">
				<Col sm={24} md={16}>
					<Card style={{ marginTop: '0' }}>
						<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">
							<Row gutter={15} justify="center" align="middle">
								<Col sm={24} md={22}>
									<Form.Item
										name="send_id"
										label="Send To"
										rules={[{ required: true, message: 'This field is required!' }]}
									>
										<Select
											size="middle"
											placeholder="Select User"
											onChange={handleChange}
											getPopupContainer={(triggerNode) => triggerNode}
											overlayStyle={{
												width: '300px', // Adjust the width as needed
												height: '100px', // Adjust the height as needed
											}}
										>
											<Select.Option key="select_user" value="select_user">
												Select User
											</Select.Option>
											<Select.Option key="all_user" value="all_user">
												All User
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							{stateId === 'select_user' && (
								<Row gutter={15} justify="center" align="middle">
									<Col sm={24} md={22}>
										<Form.Item
											name="select_user"
											label="Select User"
											rules={[{ required: true, message: "This field is required!" }]}
										>
											<Select
												className="Select-field"
												mode="multiple"
												allowClear
												placeholder="Select User"
												getPopupContainer={(triggerNode) => triggerNode}
												showSearch  // Enable search functionality
												optionFilterProp="children"  // Specify which property of the option will be used for filtering

												// Customize the filtering logic
												filterOption={(input, option) =>
													option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}

												overlayStyle={{
													width: '300px', // Adjust the width as needed
													height: 'auto', // Set height to auto
												}}
												maxTagCount={3} // Set the maximum number of displayed tags
												maxTagPlaceholder={(values) => `+ ${values.length} more`}
											>
												{UserList &&
													UserList.map((item, index) => (
														<Select.Option key={index} value={item._id}>
															{item.name}
														</Select.Option>
													))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							)}
							<Row gutter={15} justify="center" align="middle">
								<Col sm={24} md={22}>
									<Form.Item name="title" label="Title" rules={antdIsValidString('Title')}>
										<Input placeholder="Title" type="text" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={15} justify="center" align="middle">
								<Col sm={24} md={22}>
									<Form.Item name="message" label="Message Body" rules={antdIsValidString('Message')}>
										<TextArea placeholder="Message" type="text" />
									</Form.Item>
								</Col>
							</Row>
						</Form>
						<Row gutter={15} justify="center" align="middle">
							<Col sm={24} md={22}>
								<Button
									type="primary"
									className="btn-w25 btn-primary-light"
									style={{ width: '100%' }}
									onClick={() => form.submit()}
								>
									{props.detail ? 'Edit BroadCast' : 'Submit'}
								</Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	)
};

export default connect(({ broadCastNotification, global, loading, users }) => ({
	broadCastNotification: broadCastNotification,
	global: global,
	users: users,

}))(AddEdit);