import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { connect } from 'dva';
import { Image, Space, Row, Col, Empty, Modal, Card, Typography, Alert, Form, Input, Checkbox, Button, Switch, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker, Avatar, message, Descriptions } from 'antd';
import {
    LeftOutlined,
    UserOutlined,

} from "@ant-design/icons";
import Apploader from '../components/loader/loader'


import UserImage from '../../src/images/user.png'

const ImageUpload = (props) => {
    const [image, setImage] = useState(null);
    const hiddenFileInput = useRef(null);
    const [isProfile, setIsProfile] = useState({})
    const [isUser, setIsUser] = useState({})
    const [errors, setErrors] = useState({});
    const [storeImage, setStoreImage] = useState(null);
    const [loading, setLoading] = useState(true)
    console.log(isProfile)
    useEffect(() => {
        const userData = localStorage.getItem('user');
        const profileData = localStorage.getItem('profile');

        if (userData) {
            setLoading(true)
            const user = JSON.parse(userData);
            const profile = JSON.parse(profileData);

            // Now you can use 'user' and 'profile' in your component logic
            console.log('User:', user);

            setIsProfile(profile)
            setIsUser(user)
            setLoading(false)
        }
    }, []);
    const onStoreImageChange = event => {

        setStoreImage(event.target.files[0])
        const file = event.target.files[0];

        if (!(file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png')) {

            setErrors({ ...errors, imageTypeError: 'Please select image file type' });

        } else {
            setErrors({ ...errors, imageTypeError: '' });
        }
    };

    const handleUploadButtonClick = async () => {
        setLoading(true)
        try {
            const formdata = new FormData();
            formdata.append("image", storeImage);
            formdata.append("user_id", isUser._id);
            formdata.append("name", 'ADMIN');

            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                },
                body: formdata,
                redirect: "follow",
            };

            const response = await fetch(process.env.REACT_APP_ApiUrl + '/api/update/profile', requestOptions);


            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.text();



            let parsedResult
            try {
                parsedResult = JSON.parse(result);

                if (parsedResult.message) {
                    message.success(parsedResult.message)
                } else {
                    message.error(parsedResult.message)
                }

            } catch (parseError) {
                message.error('Error parsing JSON:', parseError);

                return;
            }

            const isImage = parsedResult.data[0]


            localStorage.setItem('profile', JSON.stringify(isImage));
            setLoading(false)

        } catch (error) {
            message.error('Error:', error);

        }
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    return (
        <div className="image-upload-container">
            <Apploader show={loading} />
            <div className="box-decoration">

                <Card
                    title={
                        <Space>
                            <LeftOutlined onClick={() => { props.history.push('/') }} style={{ fontSize: 20, marginBottom: 8 }} />
                            <span >Admin Profile</span>
                        </Space>
                    }
                    headStyle={{ backgroundColor: '#596b81', color: '#fff' }}
                    style={{ marginTop: "0", height: '80vh', boxShadow: '0 10px 8px rgba(0, 0, 0, 0.1)' }}
                >

                    <div onClick={handleClick} className="img-display-after">
                        {storeImage ? (
                            <img src={URL.createObjectURL(storeImage)} alt="upload image" className="img-display-after" />
                        ) : (
                            <img src={isProfile ? isProfile.image : UserImage} alt="upload image" className="img-display-after" />
                        )}

                        <input
                            id="image-upload-input"
                            type="file"
                            onChange={(e) => onStoreImageChange(e)}
                            ref={hiddenFileInput}
                            style={{ display: "none" }}
                        />
                    </div>
                    <Row style={{ marginTop: 16, marginBottom: 16, marginLeft: 16 }}>
                        <Col span={8}>
                            <span style={{ color: '#666', fontWeight: '700' }}>Email:</span>
                        </Col>
                        <Col span={16}>
                            <span htmlFor="image-upload-input" className="image-upload-span">
                                {isUser.username}
                            </span>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 16, marginBottom: 16 }}>
                        <Col span={8}>
                            <Button
                                type="primary"
                                className="btn-w25 btn-primary-light"
                                onClick={handleUploadButtonClick}
                                disabled={loading}
                                style={{ position: 'absolute', width: 200 }}
                            >
                                Upload
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
    );
}

export default connect(({ users, loading }) => ({
    users
}))(ImageUpload);;