import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Empty, Modal, Card, Typography, Alert, Form, Input, Checkbox, Button, Switch, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker } from 'antd';
import moment from 'moment';
import { connect } from 'dva';
import styles from './style.less';
import {
	antdIsValidString,

} from "../../../utils/functions";
const { Text } = Typography;
const { TextArea } = Input;
const timestemp = (new Date()).getTime();
const { RangePicker } = DatePicker;
const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 24, }, } };


const baseUrl = process.env.REACT_APP_ApiUrl

const AddEdit = props => {
	const [form] = Form.useForm();
	const { dispatch } = props;
	const [catlist, setCatlist] = useState([])
	const [count, setCount] = useState(0)
	const [dcount, setDCount] = useState(0)
	const [btnDis, setBtnDis] = useState(false)
	const [image, setImage] = useState()
	const [showImage, setShowImage] = useState()
	const [stateId, setStateId] = useState()
	const [userId, setUserId] = useState(null);
	// useEffect(() => {
	// 	setShowImage();
	// 	let unmounted = false;
	// 	let { category } = props;
	// 	if (category.list) {
	// 		setCatlist(category.list ? category.list.data : []);
	// 	}
	// 	else dispatch({ type: 'category/categoryList' });


	// 	return () => { unmounted = true; }
	// }, [dispatch])

	// useEffect(() => {
	// 	props.dispatch({ type: 'stores/storeList' });
	// }, [dispatch])

	// useEffect(() => {
	// 	let unmounted = false;
	// 	let data = props.detail;


	// 	if (props.detail) {
	// 		form.setFieldsValue({
	// 			['description']: data.description,
	// 			['name']: data.name,
	// 			['slug']: data.slug,
	// 			['gst']: data.gst,
	// 			['isActive']: data.isActive,
	// 			['store_id']: data?.storeData[0].store_title,
	// 			//   ['commission']: data.commission,
	// 		});


	// 		// let image = `${baseUrl}/categories/${data.image}`;
	// 		let image = data.image;
	// 		setShowImage(image);
	// 	}
	// 	else {
	// 		form.resetFields();
	// 		setShowImage(undefined);
	// 		const fileInput = document.querySelector('input[type="file"]');
	// 		if (fileInput) {
	// 			fileInput.value = '';
	// 		}
	// 	}
	// 	return () => { unmounted = true; }
	// }, [props.visible])

	// useEffect(() => {
	// 	let unmounted = false;
	// 	let { category } = props;
	// 	setCatlist(category.list ? category.list.data : []);
	// 	return () => { unmounted = true; }
	// }, [props.category.list])


	// const imageFun = (e) => {

	// 	setImage(e.target.files[0])
	// 	setShowImage(URL.createObjectURL(e.target.files[0]));
	// }


	useEffect(() => {
		const storedUserId = localStorage.getItem('userId');
		if (storedUserId) {
			setUserId(storedUserId);
		}
	}, []);



	const onFinish = val => {

		// val['roles'] = 'ADMIN'
		// val['type'] = 'ADMIN'

		// const formData = new FormData();


		// formData.append('user_id', '651fb9336c97afc5817b3fb4');
		// formData.append('title', val.title);
		// formData.append('message', val.message);
		// formData.append('type', val.type);
		// formData.append('roles', val.roles);

		const data = {
			user_id: userId,
			title: val.title,
			message: val.message,
			type: 'ADMIN',
			roles: 'ADMIN',
		};


		setBtnDis(true);
		// if (props.detail) {
		// 	formData.append('_id', props.detail._id);
		// 	// formData.append('slug', val.slug);
		// 	dispatch({ type: 'category/categoryEdit', payload: formData, });
		// }
		// else {
		dispatch({ type: 'broadCastNotification/broadCastAddEdit', payload: data, });
		if (data) {
			form.resetFields();
			setBtnDis(false);
		}


		// }


	}

	// useEffect(() => {
	// 	let unmounted = false;
	// 	let add = props.broadCastNotification.add
	// 	if (!unmounted && add.count > count && add.status) {
	// 		setBtnDis(false);
	// 		setCount(add.count);
	// 		props.returnData('success');
	// 	} else if (!unmounted && add.count > count && !add.status) {
	// 		setBtnDis(false);
	// 		setCount(add.count);
	// 	}

	// 	// Edit
	// 	let edit = props.broadCastNotification.edit
	// 	if (!unmounted && edit.count > dcount && edit.status) {
	// 		setBtnDis(false);
	// 		setDCount(edit.count);
	// 		props.returnData('success');
	// 	} else if (!unmounted && edit.count > dcount && !edit.status) {
	// 		setBtnDis(false);
	// 		setDCount(edit.count);
	// 	}
	// 	return () => {
	// 		unmounted = true;
	// 	}
	// }, [props.category])

	const cancelFun = () => {

		if (!props.detail)
			form.resetFields();
		props.closeModel()
	}
	//onOk={()=>form.submit()} onCancel={()=>setPicModel(false)}

	const handleChange = (val, data) => {
		setStateId(val)
	}

	return (

		<Modal visible={props.visible} title={props.detail ? 'Edit BroadCast' : 'Add BroadCast'} onCancel={cancelFun} footer={<Fragment>
			<Button onClick={cancelFun}>Cancel</Button>
			<Button type="primary" disabled={btnDis} className="btn-w25 btn-primary-light" onClick={() => form.submit()}>{props.detail ? 'Edit BroadCast' : 'Add BroadCast'}</Button>
		</Fragment>} >
			<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">
				{/* <Form.Item name="slug"  label="Slug" rules={[{ required: true, message: 'This field is required!' }]} >
				<Input placeholder="Slug" type="text" />
			</Form.Item> */}

				{/* <Form.Item
					name="store_id"
					label="Store Name"
					rules={[{ required: true, message: 'This field is required!' }]}
				>
					<Select
						size="middle"
						// defaultValue="a1"
						// defaultValue={props.detail ? props.detail?.storeData[0].store_title : ''}
						onChange={handleChange}
						getPopupContainer={(triggerNode) => triggerNode}
						overlayStyle={{
							width: '300px',   // Adjust the width as needed
							height: '100px',  // Adjust the height as needed


						}}
					// style={{ width: 200 }}
					>
						{props?.stores?.list.map((option) => (

							<Select.Option key={option?.store_title} value={option?._id} >
								{option?.store_title}
							</Select.Option>
						))}
					</Select>
				</Form.Item> */}

				<Form.Item name="title" label="Title" rules={antdIsValidString("Title")} >
					<Input placeholder="Title" type="text" />
				</Form.Item>
				<Form.Item name="message" label="Message" rules={antdIsValidString("Message")} >
					<TextArea placeholder="Message" type="text" />
				</Form.Item>

				{/* <Form.Item name="gst" label="GST %"  rules={[{ required: true, message: 'This field is required!' }]} >
				<Input placeholder="GST"  type="number" />
			</Form.Item> */}
				{/* <Form.Item name="commission" label="Commission"  rules={[{ required: true, message: 'This field is required!' }]} className="mb-0">
				<Input placeholder="commission" type="number" />
			</Form.Item> */}

				{/* <div>
					<input type="file" name="image" onChange={(e) => { imageFun(e) }} />
					{showImage == undefined ? '' :
						<img src={showImage} style={{ height: '60px', width: "60px" }} />
					}
				</div> */}

				{/* <Form.Item name="isActive" valuePropName="checked" style={{ marginTop: '10px' }}>
					<Checkbox>isActive</Checkbox>
				</Form.Item> */}
			</Form>

		</Modal>
	)
};

export default connect(({ broadCastNotification, global, loading, }) => ({
	broadCastNotification: broadCastNotification,
	global: global,

}))(AddEdit);