import { subscriptionLists } from "../services/api";
import { message } from "antd";
export default {
  namespace: "Subscription",

  state: {
    add: { count: 0 },
    edit: { count: 0 },
    del: { count: 0 },
  },

  subscriptions: {
    setup({ dispatch, history }) { },
  },

  effects: {
    *subscriptionList({ payload }, { call, put }) {
      const response = yield call(subscriptionLists, payload);
      if (!response.status) {
        message.error(response.msg || response.message || response.err, 5);
      }
      yield put({ type: "save", ...response });
    },

  },

  reducers: {
    save(state, action) {
      return { ...state, list: action };
    },

  },
};
