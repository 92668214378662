import { getStoreList, createStore, editStore, deleteStore, getStoreDetails, deleteStoreImage } from '../services/api'
import { message } from 'antd';

export default {
  namespace: 'stores',

  state: {
    list: [],
    detail: {},
    delete: false,
    add: false,
    edit: false
  },

  subscriptions: {
    setup({ dispatch, history }) { },
  },

  effects: {

    *storeList({ payload }, { call, put }) {
      let response = {};
      response = yield call(getStoreList, payload);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'list', data: [...response.data] });
    },
    *deleteStore({ payload }, { call, put }) {
      let response = {};
      response = yield call(deleteStore, payload);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'delete', message: response.status });
    },
    *deleteStoreImage({ payload }, { call, put }) {
      let response = {};
      response = yield call(deleteStoreImage, payload);
      console.log('response', response);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'delete', message: response.status });
    },
    *AddStore({ payload }, { call, put }) {
      let response = {};
      response = yield call(createStore, payload);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'add', message: response.status });
    },

    *EditStore({ payload }, { call, put }) {
      let response = {};
      response = yield call(editStore, payload);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      if (response.status) { message.success(response.msg || response.message, 5); }
      yield put({ type: 'edit', message: response.status });
    },

    *detailStore({ payload }, { call, put }) {

      const response = yield call(getStoreDetails, payload);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }

      yield put({ type: 'detail', ...response });
    },

    *clearAction({ payload }, { call, put }) {
      yield put({ type: 'clear' });
    },
  },

  reducers: {

    list(state, action) {
      return { ...state, list: [...action.data] };
    },
    detail(state, action) {
      return { ...state, detail: action };
    },
    delete(state, action) {
      return { ...state, delete: action };
    },
    deleteImage(state, action) {
      return { ...state, delete: action };
    },
    add(state, action) {
      return { ...state, add: action };
    },
    edit(state, action) {
      return { ...state, edit: action };
    },
    clear(state, action) {
      return { ...state, detail: {}, delete: false, add: false, edit: false };
    },

  },
};