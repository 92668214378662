import React from 'react';
import Apploader from './../../components/loader/loader'
import { connect } from 'dva';
import { Card, Typography, Input, Button, Table, Row, Col, Popconfirm, Modal, message, Upload, Menu, Space, Dropdown } from 'antd';
import { DeleteOutlined, EyeOutlined, CommentOutlined, CarOutlined, MoreOutlined } from '@ant-design/icons';
import uploadFile from '../products/action/uploadFile';
// import AddEdit from './action/addEdit'
import axios from 'axios';
const { Search } = Input;
const { Text } = Typography;

const baseUrl = process.env.REACT_APP_ApiUrl;

class UsersList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 1000000000, current: 1, sortBy: 'asc', addModel: false, inactive: false, searchText: '', loader: false, detail: '', count: 0, listData: [], filterValue: null,
			menuVisible: false,
			selectedMenuItem: 'all',
			filterValue2: null,
			menuVisible2: false,
			selectedMenuItem2: 'all',
		}
		setTimeout(() => document.title = 'User List', 100);
	}
	componentDidMount() {
		this.ListFun();
	}

	handleTrueClick = () => {
		this.setState({ filterValue: true, selectedMenuItem: 'true' }, () => this.ListFun());
	};

	handleFalseClick = () => {
		this.setState({ filterValue: false, selectedMenuItem: 'false' }, () => this.ListFun());
	};

	handleAllClick = () => {

		this.setState({ filterValue: null, selectedMenuItem: 'all' }, () => this.ListFun());
	};
	handlePremiumClick = () => {
		this.setState({ filterValue2: true, selectedMenuItem2: 'true' }, () => this.ListFun());
	};

	handleNotPremiumClick = () => {
		this.setState({ filterValue2: false, selectedMenuItem2: 'false' }, () => this.ListFun());
	};

	handlePremiumAllClick = () => {

		this.setState({ filterValue2: null, selectedMenuItem2: 'all' }, () => this.ListFun());
	};
	ListFun = () => {
		let search = 'page=' + (this.state.current - 1) + "&limit=" + this.state.limit + "&inactive=" + this.state.inactive + "&searchText=" + this.state.searchText + "&sortBy=" + this.state.sortBy;
		localStorage.setItem('serviceSearch', JSON.stringify(this.state))

		let searchval = { limit: this.state.limit, role: "USER" }
		this.props.dispatch({ type: 'users/getList', payload: searchval, });


	}

	ShowSizeChange = (current, size) => this.setState({ limit: size }, () => this.ListFun());
	switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
	ChangeOrder = (val) => this.setState({ sortBy: this.state.sortBy === 'asc' ? 'desc' : 'asc' }, () => this.ListFun());
	paginationFun = (val) => this.setState({ current: val.current }, () => this.ListFun());

	searchVal = (val) => {
		this.state.searchText = val
		const resultAutos = this.props.users.list.data.filter((auto) => auto.email.toLowerCase().includes(val.toLowerCase()))

		this.setState({ listData: resultAutos })

	}


	deleteItem = (id) => {

		let val = { _id: id }
		this.props.dispatch({ type: 'users/deleteItem', payload: val });
	}

	getSnapshotBeforeUpdate(prevProps, prevState) {

		let del = this.props.users.del;
		if (del.count > this.state.count && del.status) {
			this.setState({ count: del.count, btndis: false })
			return true
		} else if (del.count > this.state.count && !del.status) {
			this.setState({ count: del.count, btndis: false })
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot) {
			this.ListFun();
		}
	}

	handleDeactiveUser = async (data) => {
		let list = this.state.listData;
		let list_update = list.map((item) => {

			if (item._id === data) {
				item.user_status = !item.user_status;
			}
			return item;
		})
		this.setState({ listData: list_update })
		await axios.post(`${baseUrl}/api/user/status`, { id: data });
	}


	render() {
		const { searchText, addModel, filterValue, menuVisible, selectedMenuItem, filterValue2, menuVisible2, selectedMenuItem2 } = this.state;
		const { users } = this.props;

		if (this.state.searchText == '') {
			this.state.listData = users.list ? users.list.data : [];
		}
		let filteredData = this.state.listData;

		console.log(filteredData)




		if (filterValue !== null) {
			filteredData = users.list.data.filter((item) => item.user_status === filterValue);
			console.log(filteredData)
		}

		let filteredData2 = this.state.listData;






		if (filterValue2 !== null) {
			filteredData2 = users.list.data.filter((item) => item.isSubscriptionActive === filterValue2);
			console.log(filteredData)
		}

		const menu = (
			<Menu onClick={() => { this.setState({ menuVisible: true, }) }}>
				<Menu.Item key="true" onClick={this.handleTrueClick} style={selectedMenuItem === 'true' ? { backgroundColor: '#e6f7ff' } : null}>Active</Menu.Item>
				<Menu.Item key="false" onClick={this.handleFalseClick} style={selectedMenuItem === 'false' ? { backgroundColor: '#e6f7ff' } : null}>Deactive</Menu.Item>
				<Menu.Item key="all" onClick={this.handleAllClick} style={selectedMenuItem === 'all' ? { backgroundColor: '#e6f7ff' } : null}>All</Menu.Item>
			</Menu>
		);




		const menu2 = (
			<Menu onClick={() => { this.setState({ menuVisible2: true, }) }}>
				<Menu.Item key="true" onClick={this.handlePremiumClick} style={selectedMenuItem2 === 'true' ? { backgroundColor: '#e6f7ff' } : null}>Premium</Menu.Item>
				<Menu.Item key="false" onClick={this.handleNotPremiumClick} style={selectedMenuItem2 === 'false' ? { backgroundColor: '#e6f7ff' } : null}>Not Premium</Menu.Item>
				<Menu.Item key="all" onClick={this.handlePremiumAllClick} style={selectedMenuItem2 === 'all' ? { backgroundColor: '#e6f7ff' } : null}>All</Menu.Item>
			</Menu>
		);
		const columns = [
			// {
			// 	title: <strong className="primary-text cursor" onClick={this.ChangeOrder}>Name <i className={' fal fa-sm ' + (this.state.sortBy === 'asc' ? "fa-long-arrow-up" : "fa-long-arrow-down")} ></i></strong>,
			// 	dataIndex: 'name',

			// 	render: (val, data) => <div className={data.isActive ? "" : 'danger-text'}>{data?.name}</div>
			// },
			{ title: <strong>Email</strong>, dataIndex: 'email' },

			{
				title: (<div>  <strong> Premium </strong><span style={{ marginLeft: 35 }}>
					<Space size="middle">
						<Dropdown overlay={menu2} trigger={['click']} visible={menuVisible2} onVisibleChange={(v) => this.setState({ menuVisible2: v })}>
							<a href="#">
								<MoreOutlined style={{ color: 'black', fontSize: 20 }} />
								{/* <FilterOutlined   style={{ color: 'black' }}/> */}
							</a>
						</Dropdown>
					</Space>
				</span></div>), dataIndex: 'isSubscriptionActive', render: (val, data) => val ? 'Premium' : 'Not Premium'
			},

			{
				title: (<div>  <strong> Status </strong><span style={{ marginLeft: 35 }}>
					<Space size="middle">
						<Dropdown overlay={menu} trigger={['click']} visible={menuVisible} onVisibleChange={(v) => this.setState({ menuVisible: v })}>
							<a href="#">
								<MoreOutlined style={{ color: 'black', fontSize: 20 }} />
								{/* <FilterOutlined   style={{ color: 'black' }}/> */}
							</a>
						</Dropdown>
					</Space>
				</span></div>), dataIndex: 'user_status', render: (val, data) =>


					<div>
						<Button
							type={data?.user_status === true ? "primary" : "danger"}
							onClick={(e) => {
								this.handleDeactiveUser(data._id);
								console.log("user_status", data.user_status)
							}}
						>
							{data.user_status} {data?.user_status ? "Activate" : "Deactivate"}
						</Button>
					</div>
			},

			{
				title: <strong>Action</strong>, align: 'center', width: 150, //align:'center',
				render: (val, data) =>
					<div>

						<Button type="primary" onClick={() => {
							this.props.history.push('/users/edit/' + data._id)
						}}><EyeOutlined /></Button>&nbsp;&nbsp;
						{/* <Button type="primary" onClick={() => {
							this.props.history.push('/users/reviews/' + data._id)
						}}><CommentOutlined /></Button>&nbsp; */}


						<Popconfirm title="Are you sure delete this user?" onConfirm={e => { this.deleteItem(data._id) }} okText="Yes" cancelText="No" getPopupContainer={(triggerNode) => triggerNode}
							overlayStyle={{
								width: '300px',   // Adjust the width as needed
								height: '100px',  // Adjust the height as needed


							}} >
							<Button type="danger" ><DeleteOutlined /></Button>
						</Popconfirm>
					</div>
			},
		];

		return (
			<div>
				<Apploader show={this.props.loading.global} />
				<Row className="TopActionBar" gutter={[16, 0]} justify="space-between" align="middle">
					<Col>
						<Search placeholder="Search..." onChange={(e) => this.searchVal(e.target.value)} value={searchText}
							loading={this.props.submitting} />
					</Col>
					<Col>
					</Col>


				</Row>

				<div className="innerContainer">
					<Card style={{ marginTop: "0", }} bodyStyle={{
						padding: '0 '
					}}>
						<Table columns={columns} dataSource={filterValue === null && filterValue2 === null ? this.state.listData.reverse() : filterValue !== null && filterValue2 === null ? filteredData : filteredData2
						}
							onChange={this.paginationFun}
							rowKey={record => record._id}
							pagination={{
								position: ['bottomLeft'],
								showTotal: (total, range) => <Text style={{ marginLeft: "20px" }} type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>, showSizeChanger: true,
								responsive: true,
								onShowSizeChange: (current, size) => this.ShowSizeChange(current, size),
								pageSizeOptions: ['25', '50', '100', '250', '500'],
							}}
						/>
					</Card>
				</div>

			</div>
		);
	}
};

export default connect(({ users, loading }) => ({
	users, loading
}))(UsersList);