import React, { Component, Suspense } from 'react';
import { connect } from 'dva';
import './BasicLayout.less';
import SubRoute from '../components/SubRoute/SubRoute'
import AppSidebar from '../components/Sidebar/AppSidebar'
import AppHeader from '../components/AppHeader/AppHeader'
import axios from 'axios';
import { Modal, Layout } from 'antd';

const { Content, Sider } = Layout;

const baseUrl = process.env.REACT_APP_ApiUrl;

class BasicLayout extends Component {
	state = { mobileView: false, announcement: "" };

	async componentDidMount() {
		// Fetch announcement for seller if user is a seller
		let userRole = localStorage.getItem('role');
		if (userRole === "SELLER") {
			try {
				const res = await axios.post(`${baseUrl}/get/announcement/for/seller`);
				const announcement = res.data.announcement;
				if (announcement) {
					this.setState({ announcement: announcement.message });
				}
			} catch (error) {
				console.error("Error fetching announcement:", error);
			}
		}

		// Check if the page is loaded on a mobile device
		const isMobileView = window.innerWidth < 768; // Adjust the breakpoint as needed
		if (isMobileView) {
			this.props.dispatch({ type: 'global/toggle', payload: false }); // Dispatch action to toggle off sidebar
		}
		this.setState({ mobileView: isMobileView });
	}

	render() {
		const { global } = this.props;
		const { toggleval } = global;
		const { mobileView, announcement } = this.state;

		return (
			<Layout>
				<header className="headerdiv">
					<AppHeader {...this.props} isMobileView={mobileView} />
				</header>

				{announcement && !mobileView && (
					<div style={{ height: '50px', background: 'white' }}>
						<marquee style={{ background: "lightblue", height: '50px' }} class="ann-marquee" behavior="scroll" direction="left" scrollamount="8" loop="infinite">
							<span style={{ position: "relative", top: "15px" }}>{announcement}</span>
						</marquee>
					</div>
				)}

				<Layout>

					<>
						<Sider className="sidebarDiv" breakpoint="sm" collapsedWidth="35" collapsed={toggleval}>
							<AppSidebar {...this.props} isMobileView={mobileView} />
						</Sider>
						<Content className="contentDiv">
							<Suspense fallback={<div>Loading...</div>} >
								<SubRoute {...this.props} />
							</Suspense>
						</Content>
					</>

				</Layout>
			</Layout>
		);
	}
}

export default connect(({ global }) => ({
	global
}))(BasicLayout);
