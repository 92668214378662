

export const getTitleImage = (name) => {
    let nameTokens = name.split(' ');
    nameTokens = nameTokens.map((nameToken) => {
        return nameToken.substr(0, 1).toUpperCase() + nameToken.substr(1);
    })
    return nameTokens.join(' ');
}

// export const antdIsValidString = (fieldName, maxLength = 50, isNumber) => {
//     const validation = [
//         { required: true, message: "This field is required!" },
//         {
//             max: maxLength,
//             message: `${fieldName} must not be greater than ${maxLength} characters.`,
//         },
//         { whitespace: true, message: "Blank spaces are not allowed" },
//     ];

//     if (isNumber) {
//         validation.push({
//             required: true,
//             message: "A value must be entered",
//             pattern: new RegExp(/^[0-9]+$/),
//         });
//     }

//     return validation;
// };
export const antdIsValidString = (fieldName, isNumber) => {
    const validation = [
        { required: true, message: "This field is required!" },
        {

            // message: `${fieldName} must not be greater than  characters.`,
        },
        { whitespace: true, message: "Blank spaces are not allowed" },

    ];

    if (isNumber) {
        validation.push({
            required: true,
            message: "A value must be entered",
            pattern: new RegExp(/^[0-9]+$/),
        });
    }

    return validation;
};
