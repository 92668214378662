import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./AppSidebar.less";
import {
	DashboardOutlined,
	ShopOutlined,
	BookOutlined,
	TeamOutlined,
	UserDeleteOutlined,
	CodeSandboxOutlined,
	NotificationOutlined,
	AndroidOutlined,
	SettingOutlined,
	DeleteOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";

import axios from "axios";
import { Modal } from "antd";

const { SubMenu } = Menu;
const baseUrl = process.env.REACT_APP_ApiUrl;

const menu = [
	{
		path: "/",
		name: "Dashboard",
		icon: <DashboardOutlined />,
		auth: ["ADMIN"],
	},
	{
		path: "/users",
		name: "User List",
		icon: <TeamOutlined />,
		auth: ["ADMIN"],
	},
	{
		path: "/reportUser",
		name: "Report User List",
		icon: <UserDeleteOutlined />,
		auth: ["ADMIN"],
	},

	{
		path: "/storeCategory",
		name: "Review Categories",
		icon: <CodeSandboxOutlined />,
		auth: ["ADMIN"],
	},


	{ path: "/pages", name: "Pages", icon: <BookOutlined />, auth: ["ADMIN"] },
	{
		path: "/broadCast",
		name: "Broadcast",
		icon: <NotificationOutlined />,
		auth: ["ADMIN"],
	},


	{
		path: "/delete-request",
		name: "Delete Request",
		icon: <DeleteOutlined />,
		auth: ["ADMIN"],
	},
	{
		path: "/support",
		name: "Support",
		icon: <InfoCircleOutlined />,
		auth: ["ADMIN"],
	},
	{
		path: "/androidAppSetting",
		name: "App Settings",
		icon: <SettingOutlined />,
		auth: ["ADMIN"],
	},

];

class AppSidebar extends Component {
	constructor(props) {

		super(props);



		this.state = {
			maintenanceModeModal: false,
			isScrolled: false,
			// isMobileView
		};
		// isMobileView = this.props;
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);

	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);

	}
	getSingleSettingData = (cards, id) => {
		let result;
		if (cards != undefined) {
			cards.map((item, key) => {
				if (item.option === id) {
					result = item.value;
				}
			});
		}
		return result;
	};


	getSiteSettingData = async () => {
		try {
			let user_id = localStorage.getItem("userId");
			const res = await axios.post(`${baseUrl}/api/list/setting`, {
				_id: user_id,
			});
			let settings = res?.data?.settings;

			let userStatus = this.getSingleSettingData(settings, "userStatus");
			let maintenanceMode = this.getSingleSettingData(
				settings,
				"SELLER_WEB_UNDER_MAINTENANCE"
			);

			let user_detail = res?.data?.user_detail;

			console.log("user_detail");

			if (user_detail?.maintenance_mode_for_user) {
				this.setState({ maintenanceModeModal: true });
			} else {
				this.setState({ maintenanceModeModal: false });
			}

			if (!user_detail?.maintenance_mode_for_user) {
				if (maintenanceMode === "yes" || maintenanceMode === "Yes") {
					this.setState({ maintenanceModeModal: true });
				} else {
					this.setState({ maintenanceModeModal: false });
				}
			}

			// IF USER STATUS DEACTIVE TRUE THAN
			if (userStatus) {
				localStorage.clear();
				window.location.reload();
			}
		} catch (e) {
			console.log(e);
		}
	};

	handleScroll = () => {
		const isScrolled = window.scrollY > 60; // Adjust the value based on when you want the effect to start
		this.setState({ isScrolled });

	};

	handleRedirect = (path) => {
		// let user_role = localStorage.getItem("role");

		// if (user_role != "ADMIN") {
		//   //   this.getSiteSettingData();
		// }

		this.props.history.push(path);
	};

	render() {
		const { location, isMobileView } = this.props;

		console.log("aaya not", this.props)

		const pathSnippets = location.pathname.split("/").filter((i) => i);
		const pathval = pathSnippets[pathSnippets.length - 1] || "";
		const routepath = pathval ? "/" + pathval : "/";
		// const isScrolled = window.scrollY > 0;
		// const paddingValue = isScrolled ? 0 : 60;
		const { isScrolled, mobileView } = this.state;

		return (
			// <>

			<div

				style={{
					position: "fixed",
					top: isScrolled ? "0" : "60px",
					left: "0",

					width: isMobileView ? "30px" : "200px", // Set width based on mobile view
					height: "calc(100% - 70px)",
					overflowY: "auto",
					zIndex: 1000,
					transition: "top 0.3s ease",

				}}
			>
				<Menu
					mode="inline"
					backgroundColor="#fff"
					defaultSelectedKeys={[routepath]}
					defaultOpenKeys={[""]}
					selectedKeys={[routepath]}
					theme="dark"
					className="menu-container" // Apply the menu-container class here
				>
					{menu.map((item) => {
						let isBusinessVerfied = JSON.parse(
							localStorage.getItem("user")
						).isBussinessVerified;
						// if (item.auth.find(val => val === localStorage.getItem('role')) && !isBusinessVerfied)
						if (
							item.auth.find((val) => val === localStorage.getItem("role")) &&
							!isBusinessVerfied
						) {
							if (item.children) {
								return (
									<SubMenu
										className="submenu"
										key={item.name}
										title={item.name}
									>
										{item.children.map((itemd, index) => {
											if (
												itemd.auth.find(
													(val) => val === localStorage.getItem("role")
												)
											) {
												return (
													<Menu.Item key={itemd.path}>
														<a
															onClick={() => {
																this.handleRedirect(itemd.path);
															}}
														>
															{itemd.img ? (
																<img src={itemd.img} alt={itemd.name} />
															) : (
																itemd.icon
															)}
															<span style={{}}>
																{itemd.name}
															</span>
														</a>
													</Menu.Item>
												);
											}
										})}
									</SubMenu>
								);
							} else {
								return (
									<Menu.Item key={item.path}>
										<a
											// to={item.path}
											onClick={() => {
												this.handleRedirect(item.path);
											}}
											style={{ display: 'flex', alignItems: 'center' }}
										>
											{item.img ? (
												<img src={item.img} alt={item.name} style={{ marginRight: '8px' }} />
											) : (
												item.icon
											)}
											<span>{item.name}</span>
										</a>
									</Menu.Item>
								);
							}
						}
					})}
				</Menu>

				<Modal
					centered
					footer={null}
					width={800}
					closable={false}
					visible={this.state.maintenanceModeModal}
				>
				</Modal>
				{/* </> */}
			</div>
		);
	}
}
export default AppSidebar;
