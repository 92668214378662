import React from 'react';
import { Link } from 'react-router-dom';
import Apploader from './../../components/loader/loader'
import { connect } from 'dva';
import { Empty, Card, Menu, Image, Space, Dropdown, Typography, Alert, Input, Button, Table, Radio, Divider, Switch, Row, Col, Avatar, Pagination, Tabs, Modal, Badge, Popconfirm } from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import AddEdit from './action/addEdit'
//import styles from './login.less';
import { getTitleImage } from '../../utils/functions';
const { Search } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;
class CategoryList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 1000000000,
			// current: 1,
			current: 0,
			sortBy: 'asc', addModel: false, inactive: false,
			searchText: '', loader: false, count: 0, Addcount: 0,
			listData: [], detail: '', filterValue: null,
			menuVisible: false,
			selectedMenuItem: 'all',
		}
		setTimeout(() => document.title = 'CategoryList', 100,);
	}
	componentDidMount() {
		this.ListFun();
	}

	handleTrueClick = () => {
		this.setState({ filterValue: true, selectedMenuItem: 'true' }, () => this.ListFun());
	};

	handleFalseClick = () => {
		this.setState({ filterValue: false, selectedMenuItem: 'false' }, () => this.ListFun());
	};

	handleAllClick = () => {

		this.setState({ filterValue: null, selectedMenuItem: 'all' }, () => this.ListFun());
	};
	ListFun = () => {
		// let search = 'page=' + (this.state.current - 1) + "&limit=" + this.state.limit + "&inactive=" + this.state.inactive + "&searchText=" + this.state.searchText + "&sortBy=" + this.state.sortBy;
		localStorage.setItem('serviceSearch', JSON.stringify(this.state))

		// let searchval = { page: this.state.current - 1, limit: this.state.limit, inactive: this.state.inactive, searchText: this.state.searchText, sortBy: this.state.sortBy }
		let searchval = { page: this.state?.current, limit: this.state?.limit, inactive: this.state?.inactive, searchText: this.state?.searchText, sortBy: this.state?.sortBy }
		//this.setState({loader:true})
		this.props.dispatch({ type: 'category/categoryList', payload: searchval, });

	}

	ShowSizeChange = (current, size) => this.setState({ limit: size }, () => this.ListFun());
	switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
	ChangeOrder = (val) => this.setState({ sortBy: this.state.sortBy === 'asc' ? 'desc' : 'asc' }, () => this.ListFun());
	// paginationFun = (val) => this.setState({ current: val.current }, () => this.ListFun());

	searchVal = (val) => {
		this.state.searchText = val
		const resultAutos = this.props.category.list.data.filter((auto) => auto.name.toLowerCase().includes(val.toLowerCase()) || auto?.storeData?.store_title.toLowerCase().includes(val.toLowerCase())
		)

		this.setState({ listData: resultAutos })

	}

	createCat = (val) => {
		if (val) { this.ListFun() }
		this.setState({ detail: null, addModel: false })
	}

	deleteCat = id => {
		this.props.dispatch({ type: 'category/categoryDel', payload: { _id: id }, });
	}

	getSnapshotBeforeUpdate(prevProps, prevState) {

		if (this.props.category.del && this.props.category.del.count > this.state.count && this.props.category.del.status) {
			this.setState({ count: this.props.category.del.count })
			return true
		}
		if (this.props.category.add && this.props.category.add.count > this.state.Addcount && this.props.category.add.status) {
			this.setState({ Addcount: this.props.category.add.count, addModel: false })
			return true
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot) {
			this.ListFun()
		}
	}

	render() {
		const { inactive, limit, searchText, addModel, filterValue, menuVisible, selectedMenuItem } = this.state;
		const { category } = this.props;
		if (this.state.searchText == '') {
			this.state.listData = category.list ? category.list.data : [];
		}
		let filteredData = this.state.listData;

		console.log(filteredData)




		if (filterValue !== null) {
			filteredData = category.list.data.filter((item) => item.isActive === filterValue);
		}



		const total = 0;//list ? list.total : 0;
		const totalActive = 0 //list ?  list.totalActive : 0;


		const menu = (
			<Menu onClick={() => { this.setState({ menuVisible: true, }) }}>
				<Menu.Item key="true" onClick={this.handleTrueClick} style={selectedMenuItem === 'true' ? { backgroundColor: '#e6f7ff' } : null}>Active</Menu.Item>
				<Menu.Item key="false" onClick={this.handleFalseClick} style={selectedMenuItem === 'false' ? { backgroundColor: '#e6f7ff' } : null}>Deactive</Menu.Item>
				<Menu.Item key="all" onClick={this.handleAllClick} style={selectedMenuItem === 'all' ? { backgroundColor: '#e6f7ff' } : null}>All</Menu.Item>
			</Menu>
		);

		const columns = [
			// {
			// 	title: <strong>Image</strong>,
			// 	dataIndex: 'store_title',
			// 	//width:250,
			// 	render: (val, data) => (<div><Image
			// 		width={100}
			// 		height={100}
			// 		style={{ borderRadius: '20px' }}
			// 		src={data?.image}
			// 	/></div>)
			// },
			{
				title:
					<strong className="primary-text cursor" onClick={this.ChangeOrder}>Category Name<i className={' fal fa-sm ' + (this.state.sortBy === 'asc' ? "fa-long-arrow-up" : "fa-long-arrow-down")} ></i></strong>,
				dataIndex: 'name',
				//width:250,
				render: (val, data) => <div className={data.isActive ? "" : 'danger-text'}>{getTitleImage(val)}</div>
			},
			// { title: <strong>Slug</strong>, dataIndex: 'slug', },
			{
				title: (
					<div>
						<strong>isActive</strong>
						<span style={{ marginLeft: 35 }}>
							<Space size="middle">
								<Dropdown overlay={menu} trigger={['click']} visible={menuVisible} onVisibleChange={(v) => this.setState({ menuVisible: v })}>
									<a href="#">
										<MoreOutlined style={{ color: 'black', fontSize: 20 }} />
										{/* <FilterOutlined   style={{ color: 'black' }}/> */}
									</a>
								</Dropdown>
							</Space>
						</span>
					</div>
				),
				dataIndex: 'isActive',
				render: (value, row) => <span>{value === true ? 'True' : 'False'}</span>,
			},
			{
				title: <strong>Action</strong>, width: 200, align: 'center',
				render: (val, data) => <div onClick={e => e.stopPropagation()}>
					<Button title="View and Edit Category" type="primary" onClick={() => this.setState({ addModel: true, detail: data })}><EditOutlined /></Button>&nbsp;
					{/* <Popconfirm title="Are you sure delete this category?" onConfirm={e => { this.deleteCat(data._id); e.stopPropagation() }} okText="Yes" cancelText="No" getPopupContainer={(triggerNode) => triggerNode}
						overlayStyle={{
							width: '300px',   // Adjust the width as needed
							height: '100px',  // Adjust the height as needed


						}} >
						<Button type="danger" ><DeleteOutlined /></Button>

					</Popconfirm> */}


				</div>
			},
		];
		return (
			<div>
				<Apploader show={this.props.loading.global} />
				<Row className="TopActionBar" gutter={[16, 0]} justify="space-between" align="middle">
					<Col>
						<Search placeholder="Search..." onChange={(e) => this.searchVal(e.target.value)} value={searchText} />
					</Col>
					<Col>
						{/* <Button type="primary" onClick={() => this.setState({ addModel: true })}>Add</Button>
						 */}
						<Button type="primary" onClick={() => this.setState({ addModel: true })}>Add</Button>

					</Col>
				</Row>

				<div className="innerContainer">
					<Card style={{ marginTop: "0" }} bodyStyle={{ padding: '0 ' }}>
						<Table columns={columns} dataSource={filterValue == null ? this.state.listData : filteredData}
							// onChange={this.paginationFun}
							//pagination={{ position: ['bottomLeft'] }}
							rowKey={record => record._id}
							onRow={(record, rowIndex) => {
								return {
									// onClick: event => this.setState({ addModel: true, detail: record })
								};
							}}

							pagination={{
								position: ['bottomLeft'],
								// size: 'small',
								// defaultCurrent: 1,
								// total: total, pageSize: limit,
								showTotal: (total, range) => <Text style={{ marginLeft: "20px" }} type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>,
								showSizeChanger: true,
								responsive: true,
								onShowSizeChange: (current, size) => this.ShowSizeChange(current, size),
								pageSizeOptions: ['25', '50', '100', '250', '500'],
							}}
						/>
					</Card>
				</div>

				{/*Create New*/}
				<AddEdit visible={addModel} returnData={this.createCat} closeModel={() => this.setState({ addModel: false, detail: '' })} detail={this.state.detail} />
			</div>
		);
	}
};

export default connect(({ category, loading }) => ({
	category, loading
}))(CategoryList);