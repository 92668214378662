import React from 'react';
import { Link } from 'react-router-dom';
import Apploader from '../../components/loader/loader'
import { connect } from 'dva';
import { Empty, Card, Menu, Typography, Dropdown, Space, Popover, Alert, Input, Button, Table, Radio, Divider, Switch, Row, Col, Avatar, Pagination, Tabs, Modal, Badge, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import AddEdit from './action/addEditAudio';
import { getTitleImage } from '../../utils/functions';
import Moment from 'react-moment';
const { Search } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;

class StoreList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 1000000000,
			// current: 1,
			current: 0,
			sortBy: 'asc',
			addModel: false,
			inactive: false,
			searchText: '',
			loader: false,
			detail: '',
			filterValue: null,
			menuVisible: false,
			selectedMenuItem: 'all',
			count: 0,
			Addcount: 0,
			listData: []
		}

		setTimeout(() => document.title = 'Store List', 100,);
		this.isUpdate = false;
	}

	componentDidMount() {
		this.ListFun();

	}



	handleTrueClick = () => {
		this.setState({ filterValue: true, selectedMenuItem: 'true' }, () => this.ListFun());
	};

	handleFalseClick = () => {
		this.setState({ filterValue: false, selectedMenuItem: 'false' }, () => this.ListFun());
	};

	handleAllClick = () => {
		console.log('Clicked on All Stores');
		this.setState({ filterValue: null, selectedMenuItem: 'all' }, () => this.ListFun());
	};
	ListFun = () => {

		const data = {
			limit: this.state.limit,
			page: this.state.current
		};
		if (this.state.filterValue !== null) {
			data.isActive = this.state.filterValue;
		}

		this.props.dispatch({ type: 'stores/storeList', payload: data });
	}

	ShowSizeChange = (current, size) => this.setState({ limit: size }, () => this.ListFun());
	switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
	ChangeOrder = (val) => this.setState({ sortBy: this.state.sortBy === 'asc' ? 'desc' : 'asc' }, () => this.ListFun());

	searchVal = (val) => {
		this.state.searchText = val;
		// const resultAutos = this.props.audios.list.filter((auto) =>
		const resultAutos = this.props.stores.list.filter((auto) =>
			auto.store_title.toLowerCase().includes(val.toLowerCase())
		)
		this.setState({ listData: resultAutos })


	}

	createCat = (val) => {
		if (val) { this.ListFun() }
		this.setState({ detail: '', addModel: false })
	}

	deleteStore = id => {

		this.props.dispatch({ type: 'stores/deleteStore', payload: id });
	}

	getSnapshotBeforeUpdate(prevProps, prevState) {
		if (this.props.stores.delete) {
			this.props.dispatch({ type: 'stores/clearAction' });
			this.ListFun();
			return true
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot) {
		}
	}

	render() {
		const { inactive, limit, searchText, addModel, detail, filterValue, menuVisible, selectedMenuItem } = this.state;
		// const { audios } = this.props;
		const { stores } = this.props;
		if (this.state.searchText == '') {
			// this.state.listData = audios.list ? audios.list : [];
			this.state.listData = stores.list ? stores.list : [];

		}
		let filteredData = this.state.listData;



		if (filterValue !== null) {
			filteredData = stores.list.filter((item) => item.isActive === filterValue);


		}

		const menu = (
			<Menu onClick={() => { this.setState({ menuVisible: true, }) }}>
				<Menu.Item key="true" onClick={this.handleTrueClick} style={selectedMenuItem === 'true' ? { backgroundColor: '#e6f7ff' } : null}>Active</Menu.Item>
				<Menu.Item key="false" onClick={this.handleFalseClick} style={selectedMenuItem === 'false' ? { backgroundColor: '#e6f7ff' } : null}>Deactive</Menu.Item>
				<Menu.Item key="all" onClick={this.handleAllClick} style={selectedMenuItem === 'all' ? { backgroundColor: '#e6f7ff' } : null}>All</Menu.Item>
			</Menu>
		);

		const columns = [
			{
				title: <strong>Store Name</strong>,
				dataIndex: 'store_title'
			},
			{
				title: <strong>Contact Number</strong>,
				dataIndex: 'number'
			},
			{
				title: <strong>Location Address</strong>,
				dataIndex: 'location_name'
			},
			{
				title: (
					<div>
						<strong>isActive</strong>
						<span style={{ marginLeft: 35 }}>
							<Space size="middle">
								<Dropdown overlay={menu} trigger={['click']} visible={menuVisible} onVisibleChange={(v) => this.setState({ menuVisible: v })}>
									<a href="#">
										<MoreOutlined style={{ color: 'black', fontSize: 20 }} />
									</a>
								</Dropdown>
							</Space>
						</span>
					</div>
				),
				dataIndex: 'isActive',
				render: (value, row) => <span>{value === true ? 'True' : 'False'}</span>,
			},
			{
				title: <strong>Action</strong>, align: 'center',
				render: (val, data) => <div onClick={e => e.stopPropagation()}>
					<Button type="primary" onClick={() => { this.props.history.push('/stores/edit/' + data._id) }}><EditOutlined /></Button>&nbsp;&nbsp;
					<Popconfirm title="Are you sure delete this store?" onConfirm={e => { this.deleteStore(data._id); e.stopPropagation() }} okText="Yes" cancelText="No" getPopupContainer={(triggerNode) => triggerNode}
						overlayStyle={{
							width: '300px',   // Adjust the width as needed
							height: '100px',  // Adjust the height as needed


						}} >
						<Button type="danger" ><DeleteOutlined /></Button>
					</Popconfirm>
				</div>
			},
		];


		return (
			<div>
				<Apploader show={this.props.loading.global} />
				<Row className="TopActionBar" gutter={[16, 0]} justify="space-between" align="middle">
					<Col>
						<Search placeholder="Search..." onChange={(e) => this.searchVal(e.target.value)} value={searchText} />
					</Col>
					<Col>
						<Button type="primary" onClick={() => this.props.history.push('/stores/add')}>Add</Button>
					</Col>
				</Row>

				<div className="innerContainer">
					<Card style={{ marginTop: "0" }} bodyStyle={{ padding: '0  ' }}>
						<Table columns={columns} dataSource={filterValue == null ? this.state.listData.reverse() : filteredData}
							rowKey={record => record._id}
							onRow={(record, rowIndex) => {
								return {
									// onClick: event => this.setState({ addModel: true, detail: record })
								};
							}}
							pagination={{
								position: ['bottomLeft'],
								showTotal: (total, range) => <Text style={{ marginLeft: "20px" }} type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>, showSizeChanger: true,
								responsive: true,
								onShowSizeChange: (current, size) => this.ShowSizeChange(current, size),
								pageSizeOptions: ['25', '50', '100', '250', '500'],
							}}
						/>
					</Card>
				</div>

			</div>

		);
	}
};

export default connect(({ stores, loading }) => ({
	stores, loading
}))(StoreList);