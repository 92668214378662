import { Alert, Form, Input, Button, Typography, Popover, Tooltip, Row, Col, } from 'antd';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'dva';
//import Apploader from './../../components/loader/loader'
//import ErrorAlert from '@/components/CommonComp/AlertMessage';
import styles from './login.less'
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { message } from 'antd';
import Logo from '../../src/images/logo.png';
const { Text } = Typography;
const ChangePassword = props => {
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [fieldType, setFieldType] = useState(false);
  const [error, setError] = useState(false);
  const [charval, setCharval] = useState(false);
  const [upperval, setUpperval] = useState(false);
  const [lowerval, setLowerval] = useState(false);
  const [numval, setNumval] = useState(false);
  const [Scharval, setScharval] = useState(false);
  const [TooltipShow, setTooltipShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [valid, setValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showCurruntPassword, setShowCurruntPassword] = useState(false);

  //const fieldFocus = useRef();
  const btnFocus = useRef();
  const { dispatch } = props;

  const handelPassword = () => {
    setShowPassword(!showPassword);

  }
  const handelShowOldPassword = () => { setShowOldPassword(!showOldPassword); }
  const handelShowCurruntPassword = () => { setShowCurruntPassword(!showCurruntPassword) }

  const skipFun = (e) => {
    if (valid) setValid(false);
    if (TooltipShow) setTooltipShow(false);
    if (confirm) setConfirm(false);
    form.resetFields();
    props.skip();
  }
  const updatePasswordApi = async (email, oldPassword, newPassword) => {
    try {
      // Make the API call to update the password
      const response = await fetch(process.env.REACT_APP_ApiUrl + '/api/changePassword', {
        method: 'POST', // or 'POST' depending on your API
        headers: {
          Accept: 'application/json; ',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        },
        body: JSON.stringify({
          email: email,
          old_password: oldPassword,
          password: newPassword,
        }),
      });

      // Parse the response
      const data = await response.json();

      // Handle the response as needed
      if (response.ok) {
        // Password change was successful
        console.log('Password change successful');
        message.success(data.message)
        return true;
      } else {
        // Password change failed
        console.error('Password change failed:', data.message);
        message.error(data.message)
        return false;
      }
    } catch (error) {
      // Handle errors
      console.error('Error during password change', error);
      return false;
    }
  };

  const onFinish = async values => {
    // Assuming email is stored in local storage
    console.log('onFinish', values);
    const userData = localStorage.getItem("user");
    let user = JSON.parse(userData);

    // Check if the email is available in local storage
    if (user.username) {
      const email = user.username;
      const oldPassword = values.oldPassword;
      const newPassword = values.password;

      // Call the new function to update the password
      const passwordUpdated = await updatePasswordApi(email, oldPassword, newPassword);

      if (passwordUpdated) {
        // Password change was successful, navigate to the desired location
        props.history.push('/');
      } else {
        // Handle other cases (e.g., failure)
        setError(true);
      }
    } else {
      console.error('Email not found in local storage');
    }
  };
  const checkPassword = (value) => {
    console.log('checkpassword', value)

    var pass = value;
    let special = /[^\w\s]/g;
    let capital = /^(?=\S*[A-Z])/g;
    let number = /^(?=\S*\d)/g;
    let small = /^(?=\S*[a-z])/g;
    let specChar = special.test(pass);

    if (value !== undefined) {
      let showtooltip = number.test(pass) && capital.test(pass) && small.test(pass) && specChar && value.length > 7 && value.length < 17;

      setCharval(value.length > 7 && value.length < 17);
      setUpperval(capital.test(pass));
      setLowerval(small.test(pass));
      setNumval(number.test(pass));
      setScharval(specChar)

      if (showtooltip || value === '') {
        setTooltipShow(false)
        if (value !== '') { setValid(true) }
        return;
      } else {
        setTooltipShow(true)
        setValid(false)
        return Promise.reject('Please enter valid password!');
      }
    } else {
      setValid(false);
      return setTooltipShow(true)
    }

    // { callback(); }
    return Promise.reject('The two passwords that you entered do not match!');
  }

  useEffect(() => {
    let unmounted = false;
    //fieldFocus.current.focus();
    /*if(!unmounted &&  props.userLogin.login.count > 0){
      //dispatch({ type: 'login/clearLogin'});
    }*/
    return () => {
      unmounted = true;
    }
  }, [dispatch])



  return (
    <div>
      {/* <Fragment> */}
      {error}
      <Row type="flex" className="basicpage mobile_login" justify="space-around" align="middle">

        <Col className="basicbox">
          <div className="mainimg">

          </div>
          <div className="mainform">

            <img className="elevation-3 img-fluid mobile_login_logo" src={Logo} />
            <p class="login-box-msg">Change Password</p>


            <Form layout="vertical" form={form} name="resetpass" className="login-form" onFinish={onFinish} >
              {!props.hideOldPass &&
                <Form.Item name="oldPassword"
                  rules={[{ required: true, message: 'Please confirm your password!' },]}
                >
                  <Input type={showOldPassword ? 'text' : 'password'} className="hasLeftPrefix"
                    prefix={<LockOutlined className="site-form-item-icon" />}

                    suffix={showOldPassword ? <EyeTwoTone onClick={handelShowOldPassword} /> : <EyeInvisibleOutlined onClick={handelShowOldPassword} />}
                    maxLength="16"
                    placeholder="Current Password" />
                </Form.Item>
              }


              <Form.Item name="password"
                rules={[{ required: true, message: 'This field is required.' },
                {
                  validator: async (_, value) => await checkPassword(value)
                }
                ]}
              // validateStatus={error ? "error" : !TooltipShow ? "success" : ''}
              >
                <Input type={showPassword ? 'text' : 'password'} className={(valid ? 'hasEyeIcon' : '') + ' hasLeftPrefix'} prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password"

                  maxLength="16"
                  suffix={showPassword ? <EyeTwoTone onClick={handelPassword} /> : <EyeInvisibleOutlined onClick={handelPassword} />}
                  onFocus={() => setTooltipShow(true)}
                  onBlur={() => setTooltipShow(false)}
                />
              </Form.Item>

              <Form.Item name="Cpassword"
                dependencies={['password']}
                //validateStatus={error ? "error":''}
                rules={[{ required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.reject('');
                    }
                    if (value && getFieldValue('password') === value) {
                      if (getFieldValue('password') === value) {
                        setConfirm(true);
                        //btnFocus.current.focus();
                      }
                      return Promise.resolve();
                    }
                    setConfirm(false)
                    return Promise.reject('Passwords not match!');
                  },
                }),
                ]}
              >
                <Input type={showCurruntPassword ? 'text' : 'password'} className="hasLeftPrefix hasEyeIcon"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  maxLength="16"
                  suffix={showCurruntPassword ? <EyeTwoTone onClick={handelShowCurruntPassword} /> : <EyeInvisibleOutlined onClick={handelShowCurruntPassword} />}

                  placeholder="Confirm Password" />
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Button type="primary" className="login-form-button mobile-login-form-button" onClick={() => { props.history.push('/') }}>
                      Back
                    </Button>
                    {/* { window.location.hostname == 'seller.galinukkad.com' || window.location.hostname == 'localhost' ? <Link to={"/register"} className="mobile-login-form-register">Register now!</Link> : '' } */}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button mobile-login-form-button"   >
                      Change Password
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
      {/* </Fragment> */}
    </div>
  );
};

export default connect(({ auth, loading }) => ({
  auth
}))(ChangePassword);
