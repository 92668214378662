import React, { Component, Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "dva/router";
import Dashboard from "../../pages/dashboard";

import UsersList from "../../pages/users/list";
import reportUser from "../../pages/report_user/list";
import ReportUserView from "../../pages/report_user/action/addEdit"
import AddEditUser from "../../pages/users/action/addEdit";
// import broadCastNotification from "../../pages/BrodCast_notifications/list";
import subscription from "../../pages/Subscription/list";

import broadCastNotificationAdd from "../../pages/BrodCast_notifications/action/addEdit";
import AppSetting from "../../pages/App_Setting/action/addEdit";
import IosSetting from "../../pages/Ios_Setting/action/addEdit";
//route for audio list
import AudioList from "../../pages/stores/list";
import AddEditAudio from "../../pages/stores/action/addEditAudio";

import CategoryList from "../../pages/category/list";
import AddEditCategory from "../../pages/category/action/addEdit";

//route for notification and page list
import Notifications from "../../pages/notifications";
import PagesList from "../../pages/pages/list";
import AddEditPages from "../../pages/pages/action/addEdit";

// FAQ
import FAQ from "../../pages/FAQ/list";
import AddEditFAQ from "../../pages/FAQ/actions/addEdit";

//route for blog
import BlogsList from "../../pages/blogs/list";

import AddEditBlogs from "../../pages/blogs/action/addEdit";
import BlogsCategory from "../../pages/blog-category/list";

//contact and sitesetting
import Contact from "../../pages/contact/contact";
import SiteSetting from "../../pages/site-setting/list";
import support from "../../pages/Support/list";
import deleteRequest from "../../pages/Delete-request/list";

function hasAdmin() {
	let role = localStorage.getItem("role");
	if (role === "ADMIN") {
		return true;
	} else {
		return false;
	}
}

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			hasAdmin() ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/", state: { from: props.location } }} />
			)
		}
	/>
);

class SubRoute extends Component {
	render() {
		return (
			<div>
				{/* Dashboard */}
				<Route
					exact
					name="Dashboad"
					breadcrumbName="Dashboad"
					path={"/"}
					component={Dashboard}
				/>

				{/* User List */}
				<PrivateRoute exact path="/users" component={UsersList} />
				<PrivateRoute exact path="/reportUser" component={reportUser} />
				<PrivateRoute exact path="/users/edit/:id" component={AddEditUser} />

				<PrivateRoute exact path="/reportUser/View/:id" component={ReportUserView} />
				<Route exact path={"/pages"} component={PagesList} />
				<Route exact path={"/pages/add"} component={AddEditPages} />
				{/* 
				<Route exact path={"/stores"} component={AudioList} />
				<Route exact path={"/stores/add"} component={AddEditAudio} />
				<Route exact path={"/stores/edit/:id"} component={AddEditAudio} /> */}

				<Route exact path={"/storeCategory"} component={CategoryList} />
				<Route exact path={"/support"} component={support} />
				<Route exact path={"/delete-request"} component={deleteRequest} />

				<Route exact path={"/storeCategory/add"} component={AddEditCategory} />
				<Route
					exact
					path={"/storeCategory/edit/:id"}
					component={AddEditCategory}
				/>

				<Route exact path={"/androidAppSetting"} component={AppSetting} />
				<Route exact path={"/iosAppSetting"} component={IosSetting} />

				<PrivateRoute exact path="/pages/edit/:id" component={AddEditPages} />
				<Route exact path={"/blogs"} component={BlogsList} />
				<Route exact path={"/blogs/add"} component={AddEditBlogs} />
				<PrivateRoute exact path="/blogs/edit/:id" component={AddEditBlogs} />
				<Route exact path={`/notifications`} component={Notifications} />
				<Route exact path={"/broadCast"} component={broadCastNotificationAdd} />
				<Route exact path={"/Subscription"} component={subscription} />

				{/* <Route
					exact
					path={"/broadCast/add"}
					component={broadCastNotificationAdd}
				/> */}
				<Route exact path={`/contact`} component={Contact} />
				<Route exact path={`/settings`} component={SiteSetting} />

				<Route exact path={"/FAQ"} component={FAQ} />
				<Route exact path={"/FAQ/add"} component={AddEditFAQ} />
				<Route exact path={"/FAQ/edit/:id"} component={AddEditFAQ} />
			</div>
		);
	}
}

export default SubRoute;
