import React from "react";
import Apploader from "../../components/loader/loader";
import { connect } from "dva";
import {
	Card,
	Typography,
	Input,
	Button,
	Table,
	Row,
	Col,
	Dropdown,

	Menu,
	Popconfirm,
	Modal,
	Alert, Space
} from "antd";
import {
	MoreOutlined,
	DeleteOutlined,
	EyeOutlined,
	CloseSquareFilled

} from "@ant-design/icons";

import moment from "moment";
// import AddEdit from './action/addEdit'
import axios from "axios";
const { Search } = Input;
const { Text } = Typography;


const baseUrl = process.env.REACT_APP_ApiUrl;

class deleteRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 1000000000,
			// current: 1,
			current: 0,
			sortBy: "asc",
			addModel: false,
			inactive: false,
			selectedMenuItem: 'all',

			searchText: "",
			loader: false,
			detail: "",
			count: 0,
			Addcount: 0,
			listData: [],
		};

		setTimeout(() => (document.title = "Report List"), 100);
		this.isUpdate = false;
	}

	componentDidMount() {
		this.ListFun();
	}
	handleTrueClick = () => {
		this.setState({ filterValue: true, selectedMenuItem: 'true' }, () => this.ListFun());
	};
	ListFun = () => {

		this.props.dispatch({ type: "deleteRequest/getList", });
	};
	deleteItem = (id, userId) => {
		let val = { _id: userId };
		let val2 = { id: id, isAccountDeleted: true };

		console.log("valvalval", val, val2);

		// After the delete actions are dispatched, dispatch the action to get the updated list
		Promise.all([
			this.props.dispatch({ type: 'users/deleteItem', payload: val }),
			this.props.dispatch({ type: "deleteRequest/deleteItem", payload: val2 })
		]).then(() => {
			// Dispatch action to get the updated list
			this.props.dispatch({ type: "deleteRequest/getList", payload: val });
		}).catch(error => {
			console.error("Error deleting item:", error);
		});
	}


	ShowSizeChange = (current, size) =>
		this.setState({ limit: size }, () => this.ListFun());
	switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
	ChangeOrder = (val) =>
		this.setState(
			{ sortBy: this.state.sortBy === "asc" ? "desc" : "asc" },
			() => this.ListFun()
		);

	searchVal = (val) => {
		this.state.searchText = val;
		// const resultAutos = this.props.audios.list.filter((auto) =>

		// console.log('search', this.props.support.list.data)
		const resultAutos = this.props.deleteRequest.list.data.filter((auto) =>

			auto.name.toLowerCase().includes(val.toLowerCase())

		);
		this.setState({ listData: resultAutos });
	};




	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot) {
			this.ListFun();
		}
	}

	handleReasonButton = async (data) => {
		// alert(data); // Alert when no reasons are available

	};

	render() {

		const { inactive, limit, searchText, addModel, detail, selectedMenuItem, menuVisible } = this.state;

		const { deleteRequest, list } = this.props;
		console.log('support', deleteRequest)
		if (this.state.searchText == "") {

			this.state.listData = deleteRequest.list ? deleteRequest.list.data : [];
		}

		const menu = (
			<Menu onClick={() => { this.setState({ menuVisible: true, }) }}>
				<Menu.Item key="true" onClick={this.handleTrueClick} style={selectedMenuItem === 'true' ? { backgroundColor: '#e6f7ff' } : null}>Active</Menu.Item>
				<Menu.Item key="false" onClick={this.handleFalseClick} style={selectedMenuItem === 'false' ? { backgroundColor: '#e6f7ff' } : null}>Deactive</Menu.Item>
				<Menu.Item key="all" onClick={this.handleAllClick} style={selectedMenuItem === 'all' ? { backgroundColor: '#e6f7ff' } : null}>All</Menu.Item>
			</Menu>
		);


		const columns = [
			{
				title: <strong>  #</strong>,
				render: (text, record, index) => index + 1, // Adjust the parameters
			},
			{
				title: <strong>  Name</strong>,
				render: (record) => record.name, // Dynamic dataIndex function
			},
			{
				title: <strong>  Email</strong>,
				render: (record) => record.email, // Dynamic dataIndex function
			},

			// title: <strong>Reason</strong>,

			{
				title: (<div>  <strong> Reason </strong><span style={{ marginLeft: 35 }}>

				</span></div>), dataIndex: 'user_status', render: (val, data) =>


					<div>
						<Button
							type={"primary"}
							onClick={(e) => {
								Modal.info({
									title: 'Reason',
									content: (
										<div>
											<p>{data.reason}</p>


										</div>
									),
									onOk() { },
								});
							}}
						>
							{data.user_status} {"View Reason"}
						</Button>
					</div>
			},
			{
				title: <strong>Action</strong>, align: 'center', width: 150, //align:'center',
				render: (val, data) =>
					<div>



						<Popconfirm title="Are you sure delete this user?" onConfirm={e => { this.deleteItem(data._id, data.user_id) }} okText="Yes" cancelText="No" getPopupContainer={(triggerNode) => triggerNode}
							overlayStyle={{
								width: '300px',   // Adjust the width as needed
								height: '100px',  // Adjust the height as needed


							}} >
							<Button type="danger" ><DeleteOutlined /></Button>
						</Popconfirm>
					</div>
			},
			// render: (record) => record.reason, // Dynamic dataIndex function


		];



		return (
			<div>
				<Apploader show={this.props.loading.global} />
				<Row
					className="TopActionBar"
					gutter={[16, 0]}
					justify="space-between"
					align="middle"
				>
					<Col>
						<Search
							placeholder="Search..."
							onChange={(e) => this.searchVal(e.target.value)}
							value={searchText}
						/>
					</Col>
					{/* <Col>
							<Button type="primary" onClick={() => this.props.history.push('/stores/add')}>Add</Button>
						</Col> */}
				</Row>

				<div className="innerContainer">
					<Card
						style={{ marginTop: "0" }}
						bodyStyle={{ padding: "0  " }}
					>
						<Table
							columns={columns}
							dataSource={this.state.listData}
							rowKey={(record) => record._id}
							onRow={(record, rowIndex) => {
								return {
									// onClick: event => this.setState({ addModel: true, detail: record })
								};
							}}
							pagination={{
								position: ["bottomLeft"],
								showTotal: (total, range) => (
									<Text style={{ marginLeft: "20px" }} type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
								),
								showSizeChanger: true,
								responsive: true,
								onShowSizeChange: (current, size) =>
									this.ShowSizeChange(current, size),
								pageSizeOptions: ["25", "50", "100", "250", "500"],
							}}
						/>
					</Card>
				</div>
			</div >
		);
	}
}

export default connect(({ deleteRequest, loading }) => ({
	deleteRequest,
	loading,
}))(deleteRequest);