import { getMiles, createMiles, }
    from '../services/api'
import { message } from 'antd';

export default {
    namespace: 'MapMiles',

    state: {
        list: [],
        detail: {},
        delete: false,
        add: false,
        edit: false
    },

    subscriptions: {
        setup({ dispatch, history }) { },
    },

    effects: {

        *getMapMiles({ payload }, { call, put }) {

            const response = yield call(getMiles, payload);
            console.log('---------payload', response);
            if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
            //if(response.status) {message.success(response.msg, 5);} 
            yield put({ type: 'detail', ...response });


        },

        *addMapMile({ payload }, { call, put }) {
            let response = {};
            response = yield call(createMiles, payload);
            localStorage.setItem('mapRedius', response.data._id);

            if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
            if (response.status) { message.success(response.msg, 5); }
            yield put({ type: 'add', message: response.status });
        },

    },

    reducers: {


        detail(state, action) {
            return { ...state, detail: action };
        },


        add(state, action) {
            return { ...state, add: action };
        },


    },
};